import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty"

import ProductDesc from "./ProductDesc"

// reactstrap components
import {
  Button,
  Modal
} from "reactstrap";

import client from "../../feathers.js"

const ProductDescModalEdit = (props) => {
  const [ productDetail, setProductDetail ] = useState('')
  const [ loading, setLoading ] = useState(false)
  
  useEffect(() => {
    if(!isEmpty(props.dataEdit)){
      setProductDetail(props.dataEdit.productDetail)
    }
  }, [props.modalVisible, props.dataEdit])

  const onSubmit = async() => {
    setLoading(true)
    
    client.authenticate()
    .then(()=>{
      return client.service('products').patch(props.dataEdit._id, {
        productDetail: productDetail
      })
    })
    .then((res) => {
      props.toggleModal()
      props.updateProducts(res)
      props.notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
      setLoading(false)
    })
    .catch((err)=>{
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  return (  
    <>
      <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={() => {props.toggleModal()}}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit {!isEmpty(props.dataEdit)?props.dataEdit.name:''} Detail
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ProductDesc 
            productDetail={productDetail}
            setProductDetail={setProductDetail}
          />
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => {props.toggleModal()}}
          >
            Close
          </Button>
          <Button 
            color="primary" 
            type="button"
            onClick={() => onSubmit()}
            disabled={loading}
          >
            {loading? (
              <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                <span className="sr-only">Loading...</span>
              </div>
            ):null}
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}
 
export default ProductDescModalEdit;