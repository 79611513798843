import React, { useState } from "react";
import {connect} from 'react-redux';
import { updateDetails } from '../redux/actions/roleActions';
import { fetchCompany} from '../redux/actions/companyActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Notifications from "../components/Extra/Notifications.js"

// core components
import CompanyHeader from "../components/Headers/CompanyHeader.js";

import client from "../feathers"

const validationSchema = Yup.object().shape({
  rateMYRtoSGD: Yup.number()
    .required('Rate is required'),
  personName: Yup.string()
    .required('Person Name is required'),
  companyName: Yup.string()
    .required('Company Name is required'),
  address: Yup.string()
    .required('Address is required'),
  state: Yup.string()
    .required('State is required'),
  country: Yup.string()
    .required('Country is required'),
  postalCode: Yup.string()
    .required('PostalCode is required')
})

const CompanySetting = (props) => {
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  const selectCountry = (val,  setFieldValue) => {
    let code = CountryRegionData.find(e => e[0] === val)[1]
    setFieldValue("country", val)
    setFieldValue("countryCode", code)
    setFieldValue("state", '')
    setFieldValue("stateOrProvinceCode", '')
  }

  const selectState = (val, country, setFieldValue) => {
    let regionData = (CountryRegionData.find(e => e[0] === country)[2]).split("|")
    let stateOrProvinceCode = regionData.find(e => e.split('~')[0] === val)
    setFieldValue("state", val)
    setFieldValue("stateOrProvinceCode", stateOrProvinceCode.split('~')[1])
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  // React.useEffect(() => {
  //   const createSetting = async() => {
  //     if(props.userInfo._id){
  //       client.authenticate()
  //       .then(()=>{
  //         return client.service('companies').create({
  //           rateMYRtoSGD: "4.13",
  //           companyName: "PracWorks",
  //           address: "20, Jalan Penerbit U1/43, Temasya industrial Park, Glenmarie, Seksyen U1, 40150  ShahAlam, Selangor, Malaysia",
  //           state: "Wilayah Persekutuan (Kuala Lumpur)",
  //           country: "Malaysia",
  //           postalCode: "40150",
  //           phoneNumber: "+60163950893",
  //           countryCode: "MY",
  //           personName: "Yun Kit",
  //           stateOrProvinceCode: "14"
  //         })
  //       })
  //       .then((res) => {
  //         notificationOpen(true, 'success', "Company " + res.companyName + " Created Successfully!")
  //       })
  //       .catch((err)=>{
  //         if(err.name === "NotAuthenticated"){
  //           notificationOpen(true, 'danger', "Please Sign-in to continue!")
  //         }else{
  //           notificationOpen(true, 'danger', err.message)
  //         }
  //       })
  //     }
  //   }
  //   createSetting()
  // }, [])

  const onSubmit = async(value) => {
    if(props.companyInfo._id){
      client.authenticate()
      .then(()=>{
        return client.service('companies').patch(props.companyInfo._id, value)
      })
      .then((res) => {
        props.fetchCompany(res)
        notificationOpen(true, 'success', "Company " + res.companyName + " Updated Successfully!")
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  return (
    <>
      <CompanyHeader name={props.companyInfo.companyName} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="10">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Setting</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                    rateMYRtoSGD: props.companyInfo.rateMYRtoSGD?props.companyInfo.rateMYRtoSGD:0,
                    personName: props.companyInfo.personName?props.companyInfo.personName:"",
                    phoneNumber: props.companyInfo.phoneNumber?props.companyInfo.phoneNumber:"",
                    email: props.companyInfo.email?props.companyInfo.email:"",
                    companyName: props.companyInfo.companyName?props.companyInfo.companyName:"",
                    address: props.companyInfo.address?props.companyInfo.address:"",
                    country: props.companyInfo.country?props.companyInfo.country:"",
                    countryCode: props.companyInfo.countryCode?props.companyInfo.countryCode:"",
                    stateOrProvinceCode: props.companyInfo.stateOrProvinceCode?props.companyInfo.stateOrProvinceCode:"",
                    state: props.companyInfo.state?props.companyInfo.state:"",
                    postalCode: props.companyInfo.postalCode?props.companyInfo.postalCode:"",
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {propsChild => {
                    const {
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      handleChange,
                      handleSubmit,
                    } = propsChild;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <h6 className="heading-small text-muted mb-4">
                        Conversion Rate <strong>MYR</strong> to <strong>SGD</strong>
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="rateMYRtoSGD"
                              >
                                Rate
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="rateMYRtoSGD"
                                value={values.rateMYRtoSGD}
                                onChange={handleChange}
                                onWheel={ event => event.currentTarget.blur() }
                                placeholder="Rate"
                                type="number"
                                step="any"
                              />
                              {errors.rateMYRtoSGD && touched.rateMYRtoSGD && (
                                <div className="input-feedback">{errors.rateMYRtoSGD}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Person Information
                      </h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="email"
                            value={values.email}
                            onChange={handleChange} 
                            placeholder="Email"
                            type="text"
                          />
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="companyName"
                              >
                                Person Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="personName"
                                value={values.personName}
                                onChange={handleChange} 
                                placeholder="Person Name"
                                type="text"
                              />
                              {errors.personName && touched.personName && (
                                <div className="input-feedback">{errors.personName}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="companyName"
                              >
                                Phone Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="phoneNumber"
                                value={values.phoneNumber}
                                onChange={handleChange} 
                                placeholder="Phone Number"
                                type="text"
                              />
                              {errors.phoneNumber && touched.phoneNumber && (
                                <div className="input-feedback">{errors.phoneNumber}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Shipping Information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="companyName"
                              >
                                Company Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="companyName"
                                value={values.companyName}
                                onChange={handleChange} 
                                placeholder="Company Name"
                                type="text"
                              />
                              {errors.companyName && touched.companyName && (
                                <div className="input-feedback">{errors.companyName}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Address
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="address"
                                value={values.address}
                                onChange={handleChange} 
                                placeholder="Home Address"
                                type="text"
                              />
                              {errors.address && touched.address && (
                                <div className="input-feedback">{errors.address}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="country"
                              >
                                Country
                              </label>
                              <CountryDropdown
                                value={values.country}
                                id="country"
                                name="country" 
                                classes="form-control-alternative form-control"  
                                onChange={(val) => selectCountry(val, setFieldValue)} 
                                />
                              {errors.country && touched.country && (
                                <div className="input-feedback">{errors.country}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="state"
                              >
                                State
                              </label>
                              <RegionDropdown
                                country={values.country}
                                id="state"
                                value={values.state}
                                classes="form-control-alternative form-control"
                                onChange={(val) => selectState(val, values.country, setFieldValue)} />
                              {errors.state && touched.state && (
                                <div className="input-feedback">{errors.state}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="postalCode"
                              >
                                Postal code
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="postalCode"
                                value={values.postalCode}
                                onChange={handleChange} 
                                onWheel={ event => event.currentTarget.blur() }
                                placeholder="Postal code"
                                type="number"
                              />
                              {errors.postalCode && touched.postalCode && (
                                <div className="input-feedback">{errors.postalCode}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
};


const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  companyInfo: state.company.data,
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
  fetchCompany: fetchCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);