/*eslint-disable*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { connect } from 'react-redux';
import { fetchMargins } from '../redux/actions/marginsActions';
import { fetchProducts, pushProducts, updateProducts, moveProducts, removeProducts } from '../redux/actions/productsActions';
import moment from 'moment';
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"
import getSymbolFromCurrency from 'currency-symbol-map'

// reactstrap components
import {
  Button,
  // Badge,
  Card,
  CardHeader,
  CardFooter,
  Input,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import ProductModal from "../components/Products/ProductModal.js"
import ProductModalEdit from "../components/Products/ProductModalEdit.js"
import ProductDescModalEdit from "../components/Products/ProductDescModalEdit.js"
import ModalPreview from "../components/Extra/ModalPreview.js"

import AssignProducts from "../components/UserProducts/AssignProducts.js"
import AssignMargin from "../components/Products/AssignMargin.js"
import UpdateSupplier from "../components/Products/UpdateSupplier.js"
import DisableProducts from "../components/Products/DisableProducts.js"
import ActiveProducts from "../components/Products/ActiveProducts.js"

import ModalConfirmation from "../components/Extra/ModalConfirmation.js"
import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"
import axios from "axios"
import env from "../env.js"

const PAGESIZE = 20;
var typingTimer = 0;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

function getConfirmTitle(modalEvent){
  if(modalEvent === 'duplicate'){
    return "Product Duplicate"
  }else if(modalEvent === 'updateAll'){
    return "Update All Product"
  }else if(modalEvent === 'delete'){
    return "Delete Product"
  }else{
    return "Product Status"
  }
}

function getConfirmContent(modalEvent, status){
  if(modalEvent === 'duplicate'){
    return "Are you sure want to duplicate this?"
  }else if(modalEvent === 'updateAll'){
    return "Update All Merchant Product Info(Except Margin)"
  }else if(modalEvent === 'delete'){
    return "Are you sure want to Delete this Product?"
  }else{
    if(status){
      return "Are you sure want to Disable this?"
    }else{
      return "Are you sure want to Enable this?"
    }
  }
}

function sourceName(source){
  if(source === 'csv'){
    return "own"
  }else if(source === 'csv-prefix'){
    return "csv-assigned"
  }else if(source === 'sticker-prefix'){
    return "sticker-assigned"
  }else if(source === 'certificate-prefix'){
    return "certificate-assigned"
  }else if(source === 'document-prefix'){
    return "document-assigned"
  }else if(source === 'poster-prefix'){
    return "poster-assigned"
  }else if(source === 'loose-sheet-prefix'){
    return "loose-sheet-assigned"
  }else{
    return source
  }
}

const Products = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalVisiblePreview, setModalVisiblePreview ] = useState(false)
  // const [ modalVisibleMarginEdit, setModalVisibleMarginEdit ] = useState(false)
  const [ modalVisibleDetailEdit, setModalVisibleDetailEdit ] = useState(false)
  const [ modalVisibleAssignMargin, setModalVisibleAssignMargin ] = useState(false)
  const [ modalVisibleUpdateSupplier, setModalVisibleUpdateSupplier ] = useState(false)
  const [ modalVisibleDisableProduct, setModalVisibleDisableProduct ] = useState(false)
  const [ modalVisibleActiveProduct, setModalVisibleActiveProduct ] = useState(false)
  const [ modalVisibleAssign, setModalVisibleAssign ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ modalEvent, setModalEvent ] = useState('disable')
  const [ dataEdit, setDataEdit ] = useState({})
  const [ statusOnChange, setStatusOnChange ] = useState({id:'', status:true})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ checkboxChecked, setCheckboxChecked ] = useState([])
  const [ userList, setUserList ] = useState([])
  const [ regexProductName, setRegexProductName ] = useState('')
  const [ activeProduct, setActiveProduct ] = useState(5)
  const [ supplier, setSupplier ] = useState('')

  useEffect(() => {
    if(props.role !== 'superadmin'){
      client.authenticate()
      .then(async(auth)=>{
        let numbOfActiveProduct = props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5

        let numOfActive = await client.service('products').find({
          query: {
            source: {
              $in: [
              "csv-prefix",
              "sticker-prefix",
              "certificate-prefix",
              "document-prefix",
              "poster-prefix",
              "loose-sheet-prefix"
              ]
            },
            status: true,
            userId: props.userId,
            $select: ['_id'],
            $limit: numbOfActiveProduct
          }
        })
        
        setActiveProduct(numOfActive.data.length)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }, [props.userId, props.role])

  useEffect(() => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      
      fetchData(props.userId, props.fetchProducts, notificationOpen, 0, 1, props.fetchMargins, regexProductName, supplier)

    }, 500);

  }, [props.userId, props.fetchProducts, props.fetchMargins, regexProductName, supplier])

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalPreview = () => {
    setModalVisiblePreview(!modalVisiblePreview)
  }

  // const toggleModalMarginEdit = () => {
  //   setModalVisibleMarginEdit(!modalVisibleMarginEdit)
  // }

  const toggleModalAssignMargin = () => {
    setModalVisibleAssignMargin(!modalVisibleAssignMargin)
  }

  const toggleModalUpdateSupplier = () => {
    setModalVisibleUpdateSupplier(!modalVisibleUpdateSupplier)
  }
  
  const toggleModalDisableProduct = () => {
    setModalVisibleDisableProduct(!modalVisibleDisableProduct)
  }

  const toggleModalActiveProduct = () => {
    setModalVisibleActiveProduct(!modalVisibleActiveProduct)
  }

  const assignMargin = () => {
    if(checkboxChecked.length > 0){
      toggleModalAssignMargin()
    }else{
      notificationOpen(true, 'warning', "No Product is selected!")
    }
  }

  const updateSupplier = () => {
    if(checkboxChecked.length > 0){
      toggleModalUpdateSupplier()
    }else{
      notificationOpen(true, 'warning', "No Product is selected!")
    }
  }

  const disableProducts = () => {
    if(checkboxChecked.length > 0){
      toggleModalDisableProduct()
    }else{
      notificationOpen(true, 'warning', "No Product is selected!")
    }
  }

  const activeProductsToggle = () => {
    if(checkboxChecked.length > 0){
      toggleModalActiveProduct()
    }else{
      notificationOpen(true, 'warning', "No Product is selected!")
    }
  }

  const toggleModalDetailEdit = () => {
    setModalVisibleDetailEdit(!modalVisibleDetailEdit)
  }

  const toggleModalAssign = () => {
    setModalVisibleAssign(!modalVisibleAssign)
  }

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    if(modalEvent === 'duplicate'){
      duplicateProduct()
    }else if(modalEvent === 'delete'){
      deleteProduct()
    }else{
      productStatus(statusOnChange.id, statusOnChange.status)
    }
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchProducts, notificationOpen, skip, currentPage, fetchMargins, regexProductName, filterSupplier) => {
    if(userId){
      let query = {
        userId: userId,
        $skip: skip,
        orRegex: { name: regexProductName },
        $limit: PAGESIZE,
        $sort: {
          createdAt: -1
        }
      }

      if(filterSupplier){
        query = {
          userId: userId,
          supplier: filterSupplier,
          $skip: skip,
          orRegex: { name: regexProductName },
          $limit: PAGESIZE,
          $sort: {
            createdAt: -1
          }
        }
      }

      client.authenticate()
      .then(()=>{
        return client.service('products').find({
          query,
        })
      })
      .then((res) => {
        setCheckboxChecked([])
        setPagination(paginationCount(res.total, currentPage))
        fetchProducts(res.data)
      })
      .then(()=>{
        return client.service('margins').find({
          query: {
            userId: userId,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        fetchMargins(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  const productStatus = (_id, status) => {
    function toggleStatus(){
      client.service('products').patch(_id, {status: !status})
      .then((res) => {
        if(!status){
          if(res.source.includes('prefix')){
            setActiveProduct(activeProduct+1)
          }
        }else{
          if(res.source.includes('prefix')){
            setActiveProduct(activeProduct-1)
          }
        }
        props.updateProducts(res)
        notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }

    if(!status){
      client.authenticate()
      .then(async(auth)=>{
        let numbOfActiveProduct = props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5

        let numOfActive = await client.service('products').find({
          query: {
            source: {
              $in: [
              "csv-prefix",
              "sticker-prefix",
              "certificate-prefix",
              "document-prefix",
              "poster-prefix",
              "loose-sheet-prefix"
              ]
            },
            status: true,
            userId: props.userId,
            $select: ['_id'],
            $limit: numbOfActiveProduct
          }
        })

        // Check numbOfActiveProduct
        if(numOfActive.data.length < numbOfActiveProduct){
          toggleStatus()
        }else{
          notificationOpen(true, 'danger', "Exceed Number of Active Product!")
        }
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }else{
      // If disable
      toggleStatus()
    }    
  }

  // const listItemTop = (_id) => {
  //   client.authenticate()
  //   .then(()=>{
  //     return client.service('products').patch(_id, {dateToFirst: new Date()})
  //   })
  //   .then((res) => {
  //     props.updateProducts(res)
  //     props.moveProducts(res)
  //     notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
  //   })
  //   .catch((err)=>{
  //     if(err.name === "NotAuthenticated"){
  //       notificationOpen(true, 'danger', "Please Sign-in to continue!")
  //     }else{
  //       notificationOpen(true, 'danger', err.message)
  //     }
  //   })
  // }
  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchProducts, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1, props.fetchMargins, regexProductName, supplier)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchProducts, notificationOpen, (i-1)*PAGESIZE, i, props.fetchMargins, regexProductName, supplier)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchProducts, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1, props.fetchMargins, regexProductName, supplier)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  const duplicateProduct = () => {

    let cloneData = cloneDeep(dataEdit)
    delete cloneData._id
    delete cloneData.__v
    delete cloneData.updatedAt
    delete cloneData.updatedBy
    delete cloneData.userId
    delete cloneData.createdAt
    delete cloneData.createdBy
    delete cloneData.productImg

    delete cloneData.status

    cloneData.timelines.map(x => {
      delete x._id
      return x
    })
    if(cloneData.manualCostBetween){
      cloneData.manualCostBetween.map(x => {
        delete x._id
        return x
      })
    }
   
    if(cloneData.additionOption){
      if(cloneData.additionOption.length > 0){
        cloneData.additionOption.map(x => {
          delete x._id
          x.option.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(cloneData.items){
      if(cloneData.items.length > 0){
        cloneData.items.map(x => {
          delete x._id
          x.amountBetween.map(x1 => {
            delete x1._id
            return x1
          })
          x.variations.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(isEmpty(cloneData.csvFile)){
      cloneData.csvFile = {
        fileName: '',
        fileUrl: '',  
      }
    }

    cloneData.name = cloneData.name + "-duplicate"
    
    client.authenticate()
    .then(()=>{
      return client.service('products').create(cloneData)
    })
    .then((res) => {
      props.pushProducts(res)
      notificationOpen(true, 'success', "Product " + res.name + " Added Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const updateAllProduct = (oldName, newName, newData) => {
    let cloneData = cloneDeep(newData)
    delete cloneData._id
    delete cloneData.__v
    delete cloneData.updatedAt
    delete cloneData.updatedBy
    delete cloneData.userId
    delete cloneData.createdAt
    delete cloneData.createdBy
    delete cloneData.productImg
    delete cloneData.timelines
    delete cloneData.artworkLink
    delete cloneData.artworkServiceAmount
    delete cloneData.artworkoption
    delete cloneData.artworkServices
    delete cloneData.availability
    delete cloneData.category
    delete cloneData.code
    delete cloneData.dateToFirst
    delete cloneData.description
    delete cloneData.keywords
    delete cloneData.mostPopular
    delete cloneData.shippingInfo
    delete cloneData.packageSize
    delete cloneData.productDetail
    delete cloneData.marginName
    delete cloneData.marginSuperName
    delete cloneData.source
    
    if(cloneData.manualCostBetween){
      cloneData.manualCostBetween.map(x => {
        delete x._id
        return x
      })
    }
    if(cloneData.additionOption){
      if(cloneData.additionOption.length > 0){
        cloneData.additionOption.map(x => {
          delete x._id
          x.option.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(cloneData.items){
      if(cloneData.items.length > 0){
        cloneData.items.map(x => {
          delete x._id
          x.amountBetween.map(x1 => {
            delete x1._id
            return x1
          })
          x.variations.map(x1 => {
            delete x1._id
            return x1
          })
          return x
        })
      }
    }

    if(isEmpty(cloneData.csvFile)){
      cloneData.csvFile = {
        fileName: '',
        fileUrl: '',  
      }
    }

    cloneData.name = oldName

    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}updateAllProduct`,
      {
        data: cloneData,
        newName,
        userId: props.userId
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      notificationOpen(true, 'success', "All Product " + cloneData.name + " Update Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const updateAllProductLight = (oldName, newName, newData) => {
    let data = {
      name: oldName,
      supplier: newData.supplier
    }
   
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}updateAllProduct`,
      {
        data: data,
        newName,
        userId: props.userId
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      notificationOpen(true, 'success', "All Product " + newName + " Update Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const handleCheckboxChange = (id) => {
    let cloneCheckbox = cloneDeep(checkboxChecked)
    if(cloneCheckbox.includes(id)){
      cloneCheckbox = cloneCheckbox.filter(item => item !== id)
    }else{
      cloneCheckbox.push(id)
    }
    setCheckboxChecked(cloneCheckbox)
  }

  const handleCheckAll = (check) => {
    let cloneCheckbox = cloneDeep(checkboxChecked)
    cloneCheckbox = []
    if(check){
      props.productsData.map(x => {
        cloneCheckbox.push(x._id)
        return x
      })
    }
    setCheckboxChecked(cloneCheckbox)
  }

  const assignProduct = () => {
    if(checkboxChecked.length > 0){
      client.authenticate()
      .then((auth)=>{
        return axios.post(`${client.io.io.uri}fetchUserList`,
        {},
        {
          headers: {
            'Authorization': auth.accessToken
          }
        })
      })
      .then((res) => {
        setUserList(res.data)
        toggleModalAssign()
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }else{
      notificationOpen(true, 'warning', "No Product is selected!")
    }
  }

  const deleteProduct = () => {
    client.authenticate()
    .then(()=>{
      return client.service('products').remove(dataEdit._id)
    })
    .then((res) => {
      props.removeProducts(res)
      notificationOpen(true, 'success', "Product " + res.name + " Delete Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  return (  
    <>
      <div className="header pb-6 pt-4 pt-md-7">
        <span className="mask" style={{backgroundColor: '#fff'}} />
        <Container className="align-items-center" fluid>
          {
            props.role === 'superadmin'? (
              <Row>
                <Col md="12">
                  <p className="m-0 font-weight-bold">
                    Conversion rate <strong>MYR</strong> to <strong>SGD</strong>
                  </p>
                  <h1 className="display-2">{props.rateMYRtoSGD}</h1>
                  <Button
                    color="secondary"
                    href="/superadmin/setting"
                  >
                    Edit Rate
                  </Button>
                </Col>
              </Row>
            ): (
              <Row>
                <Col md="2">
                  <span className="font-weight-bold">Currency</span>
                  <h1 className="display-3 text-uppercase">{props.currency}</h1>
                </Col>
                <Col md="3">
                  <span className="font-weight-bold">Max. Active Product</span>
                  <h1 className="display-3">{props.userInfo.numbOfActiveProduct}</h1>
                </Col>
                <Col md="4">
                  <span className="font-weight-bold">Utilised</span>
                  <h1 className="display-3">{activeProduct}</h1>
                </Col>
              </Row>   
            )
          }
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col md="4">
                    <h3 className="text-white mb-0">Products tables</h3>
                  </Col>
                  <Col md="8" className="text-right">
                    {
                      props.role === 'superadmin'? (
                        <>
                          <Button
                            color="warning"
                            size="sm"
                            onClick={assignProduct}
                          >
                            <span className="btn-inner--text">Assign Products</span>
                          </Button>
                          <Button
                            color="warning"
                            size="sm"
                            onClick={updateSupplier}
                          >
                            <span className="btn-inner--text">Update Supplier</span>
                          </Button>
                        </>   
                      ): (
                        <>
                          <Button
                            color="success"
                            size="sm"
                            onClick={activeProductsToggle}
                          >
                            <span className="btn-inner--text">Active Product</span>
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={disableProducts}
                          >
                            <span className="btn-inner--text">Disable Product</span>
                          </Button>
                          <Button
                            color="warning"
                            size="sm"
                            onClick={assignMargin}
                          >
                            <span className="btn-inner--text">Assign Margin</span>
                          </Button>
                        </>
                      )
                    }
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--text">Create</span>
                    </Button>
                  </Col>
                </Row>
                <hr className="my-1" />
                <div className="d-flex justify-content-end align-items-center">
                  {/* <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle caret color="secondary" className="text-uppercase" size="sm">
                      test
                    </DropdownToggle>
                    <DropdownMenu right>
                      <div className="editInputs-colors-scrollbar mac-scrollbar">
                        <DropdownItem 
                          className="text-capitalize" 
                          value={'csv'} 
                        >
                          CSV
                        </DropdownItem>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle caret color="secondary" className="text-uppercase btn-sm">
                      {supplier}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      <div className="editInputs-colors-scrollbar mac-scrollbar">
                        <DropdownItem 
                          className="text-capitalize text-white bg-danger" 
                          value={''} 
                          onClick={() => {
                            setSupplier('')
                          }}
                        >
                          Clear
                        </DropdownItem>
                        {
                          env.suppliers.map((x, i) => {
                            return (
                              <DropdownItem 
                                key={i}
                                className="text-uppercase" 
                                value={x} 
                                onClick={() => {
                                  setSupplier(x)
                                }}
                              >
                                {x}
                              </DropdownItem>
                            )
                          })
                        }
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Input
                    placeholder="Search Product Name"
                    className="w-auto"
                    type="text"
                    bsSize="sm"
                    size={29}
                    value={regexProductName}
                    onChange={(e) => setRegexProductName(e.target.value)}
                  />
                </div>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush" style={{minHeight: 200}}>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        <input
                          className=""
                          type="checkbox"
                          id="rememberMe"
                          // checked={values.rememberMe}
                          // value={values.rememberMe}
                          onChange={(e) => handleCheckAll(e.target.checked)}
                        />
                      </th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Source</th>
                      {
                        props.role === 'superadmin'? (
                          <th scope="col">Supplier</th>
                        ): null
                      }
                      <th scope="col">Merchant Algorithm</th>
                      <th>Last Update on</th>
                      {
                        props.role !== 'superadmin'? (
                          <th scope="col">Status</th>
                        ): null
                      }
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props.productsData.length > 0? (
                      props.productsData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              {/* <Media className="align-items-center">
                                <a
                                  href="/#"
                                  className="avatar rounded-circle mr-3"
                                  onClick={(e) => e.preventDefault()}
                                >
                                   {v.productImg.length > 0? (
                                    <div
                                      className="img rounded-circle overflow-hidden bg-white d-inline-block"
                                      style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                                    >
                                      <img
                                        alt="entreship sol"
                                        className="w-100"
                                        src={v.productImg[0].fileUrl}
                                      />
                                    </div>
                                  ):(
                                    <i className="ni ni-image"></i>
                                  )}
                                </a>
                                <Media>
                                </Media>
                              </Media> */}
                                <input
                                  className=""
                                  type="checkbox"
                                  id={v._id}
                                  checked={checkboxChecked.includes(v._id)}
                                  value={checkboxChecked.includes(v._id)}
                                  onChange={(e) => handleCheckboxChange(v._id)}
                                />
                            </th>
                            <td>
                              <span className="mb-0 text-sm">
                                {v.name}
                              </span>
                            </td>
                            {/* <td>
                              {
                                v.source === 'csv'? (
                                  <>
                                    <span>-</span>
                                  </>
                                ): (
                                  <>
                                    <span>{new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' }).format(v.netAmount / props.rateMYRtoSGD)}</span>
                                    <br/>
                                    <span>{new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' }).format((v.netAmount))}</span>
                                  </>
                                )
                              }
                            </td> */}
                            <td>{v.source? sourceName(v.source) : "-"}</td>
                            {
                              props.role === 'superadmin'? (
                                <td className="text-uppercase">{v.supplier? v.supplier : "-"}</td>
                                ): null
                            }
                            <td>{!isEmpty(v.marginName)?v.marginName.marginName:'-'}</td>
                            <td>{moment(v.updatedAt).format("MMM Do YYYY")}</td>
                            {
                              props.role !== 'superadmin'? (
                                <td>
                                  <span className={classNames("badge", {
                                    "bg-success text-secondary": v.status,
                                    "bg-danger": !v.status
                                  })}>
                                    {v.status?"Active": "Inactive"}
                                  </span>
                                </td>
                              ): null
                            }
                            {/* <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.status? "bg-success": "bg-danger"} />
                                {v.status?"Active":"Disabled"}
                              </Badge>
                            </td> */}
                            {/* <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td>{moment(v.updatedAt).format("MMM Do YYYY")}</td> */}
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    className="bg-primary text-white"
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem>
                                  {
                                    props.role !== 'superadmin'? (
                                      <DropdownItem
                                        onClick={() => 
                                          {
                                            setStatusOnChange({id: v._id, status: v.status});
                                            setDataEdit(v)
                                            toggleModalConfirm()
                                            setModalEvent('disable')
                                          }
                                        }
                                      >
                                        {v.status?"Disabled":"Enable"}
                                      </DropdownItem>
                                    ): null
                                  }
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalPreview()}}
                                  >
                                    Preview
                                  </DropdownItem>
                                  {/* {
                                    props.role === 'superadmin'? (
                                      <DropdownItem
                                        onClick={() => {
                                          setDataEdit(v)
                                          toggleModalConfirm()
                                          setModalEvent('updateAll')
                                        }}
                                      >
                                        Update all product
                                      </DropdownItem>
                                    ): null
                                  } */}
                                  {/* <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalDetailEdit()}}
                                  >
                                    Edit Detail
                                  </DropdownItem> */}
                                  {
                                    props.role === 'superadmin'? (
                                      <DropdownItem
                                        onClick={() => {
                                          setDataEdit(v)
                                          toggleModalConfirm()
                                          setModalEvent('duplicate')
                                        }}
                                      >
                                        Duplicate
                                      </DropdownItem>
                                    ): null
                                  }
                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      setDataEdit(v)
                                      toggleModalConfirm()
                                      setModalEvent('delete')
                                    }}
                                  >
                                      Delete
                                  </DropdownItem>
                                  {/* <DropdownItem
                                    onClick={() => listItemTop(v._id)}
                                  >
                                    List Top
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })
                    ): (
                      <tr>
                        <td>
                          <span style={{padding: '0px 10px'}}>No Record Found!</span>
                        </td>
                      </tr>
                      )
                    }
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <ProductModal 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
        notificationOpen={notificationOpen} 
        pushProducts={props.pushProducts}
        currency={props.currency}
        rateMYRtoSGD={props.rateMYRtoSGD}
        marginsData={props.marginsData}
        role={props.role}
      />
      <ProductModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen} 
        updateAllProduct={updateAllProduct}
        updateAllProductLight={updateAllProductLight}
        updateProducts={props.updateProducts}
        currency={props.currency}
        rateMYRtoSGD={props.rateMYRtoSGD}
        marginsData={props.marginsData}
        productsData={props.productsData}
        role={props.role}
        userId={props.userId}
      />
      <ProductDescModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleDetailEdit} 
        toggleModal={toggleModalDetailEdit}
        notificationOpen={notificationOpen} 
        updateProducts={props.updateProducts}
        rateMYRtoSGD={props.rateMYRtoSGD}
      />
       <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title={getConfirmTitle(modalEvent)}
        content={getConfirmContent(modalEvent, statusOnChange.status)}
      />
      <AssignProducts 
        userList={userList}
        checkboxChecked={checkboxChecked}
        productsData={props.productsData}
        modalVisible={modalVisibleAssign} 
        toggleModal={toggleModalAssign}
        notificationOpen={notificationOpen}
        marginsData={props.marginsData}
      />
      <UpdateSupplier 
        checkboxChecked={checkboxChecked}
        modalVisible={modalVisibleUpdateSupplier} 
        toggleModal={toggleModalUpdateSupplier}
        notificationOpen={notificationOpen}
        userId={props.userId}
        updateProducts={props.updateProducts}
        productsData={props.productsData}
      />
      <AssignMargin 
        checkboxChecked={checkboxChecked}
        modalVisible={modalVisibleAssignMargin} 
        toggleModal={toggleModalAssignMargin}
        notificationOpen={notificationOpen}
        updateProducts={props.updateProducts}
        marginsData={props.marginsData}
        productsData={props.productsData}
      />
      <DisableProducts 
        activeProduct={activeProduct}
        setActiveProduct={setActiveProduct}
        checkboxChecked={checkboxChecked}
        modalVisible={modalVisibleDisableProduct} 
        toggleModal={toggleModalDisableProduct}
        notificationOpen={notificationOpen}
        updateProducts={props.updateProducts}
        productsData={props.productsData}
      />
      <ActiveProducts 
        userId={props.userId}
        numbOfActiveProduct={props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5}
        activeProduct={activeProduct}
        setActiveProduct={setActiveProduct}
        userInfo={props.userInfo}
        checkboxChecked={checkboxChecked}
        modalVisible={modalVisibleActiveProduct} 
        toggleModal={toggleModalActiveProduct}
        notificationOpen={notificationOpen}
        updateProducts={props.updateProducts}
        productsData={props.productsData}
      />
      <ModalPreview 
        dataEdit={dataEdit}
        userId={props.userId}
        currency={props.currency}
        modalVisible={modalVisiblePreview} 
        toggleModal={toggleModalPreview}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 
const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  role: state.role.details.user?state.role.details.user.role:'',
  currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
  userInfo: state.role.details.user?state.role.details.user:{},
  productsData: state.products.data,
  companyInfo: state.company.data,
  marginsData: state.margins.data,
  rateMYRtoSGD: state.company.data.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
});

const mapDispatchToProps = {
  fetchProducts: fetchProducts,
  pushProducts: pushProducts,
  updateProducts: updateProducts,
  moveProducts: moveProducts,
  removeProducts: removeProducts,
  fetchMargins: fetchMargins
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);