import firebase from "firebase/app";
import "firebase/storage";

// es stage
// const firebaseConfig = {
//   apiKey: "AIzaSyBxSRlpEIwr18RLHEGj3KFMQZJ_glVCBBg",
//   authDomain: "entreship-sol-60c3e.firebaseapp.com",
//   projectId: "entreship-sol-60c3e",
//   storageBucket: "entreship-sol-60c3e.appspot.com",
//   messagingSenderId: "380370240380",
//   appId: "1:380370240380:web:c6e093825207be6a375af9"
// };

// live
const firebaseConfig = {
  apiKey: "AIzaSyCxq_eUTXOp0Er4aaKFKmYcZAcnC02Q96I",
  authDomain: "print-online-admin.firebaseapp.com",
  projectId: "print-online-admin",
  storageBucket: "print-online-admin.appspot.com",
  messagingSenderId: "272412166486",
  appId: "1:272412166486:web:ce71b3b98b399218b4aef7"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default   };