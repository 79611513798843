import React from "react";
import cloneDeep from "lodash/cloneDeep"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Table,
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

const DocumentForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  source,
  currency
}) => {

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', amount: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  return (  
    <>
      <Row>
        <Col md="3">
          <div className="mb-2">
            <small className=" font-weight-bold">
              Base Price (<span className="text-uppercase">{currency}</span>)
            </small>
          </div>
          <FormGroup
            className={classnames("mb-3", {
              focused: amountFocused
            }, {
              "has-danger": errors.netAmount && touched.netAmount
            })}
          >
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-money-coins" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Product Net Amount"
                type="number"
                id="netAmount"
                value={Number(values.netAmount).toString()}
                onChange={handleChange}
                onFocus={e => setAmountFocused(true)}
                onBlur={e => {
                  setAmountFocused(false);
                  handleBlur(e)
                }}
                onWheel={ event => event.currentTarget.blur() }
              />
            </InputGroup>
            {errors.netAmount && touched.netAmount && (
              <div className="input-feedback">{errors.netAmount}</div>
            )}
          </FormGroup>
        </Col>
        {
          source !== 'document'? (
            <>
              <Col md="3">
                <div className="mb-2">
                  <small className=" font-weight-bold">
                    Merging Base Price (<span className="text-uppercase">{currency}</span>)
                  </small>
                </div>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-money-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Merging fee"
                      type="number"
                      id="mergingFee"
                      value={Number(values.mergingFee).toString()}
                      onChange={handleChange}
                      onWheel={ event => event.currentTarget.blur() }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <div className="mb-2">
                  <small className=" font-weight-bold">
                    Merging Fee (Percent)
                  </small>
                </div>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-percent" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Mergin Variable"
                      type="number"
                      id="merginVariable"
                      value={Number(values.merginVariable).toString()}
                      onChange={handleChange}
                      onWheel={ event => event.currentTarget.blur() }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </>
          ): null
        }
      </Row>
      
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className="text-capitalize font-weight-bold">
                {source} Materials
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => setItems(items.concat({
                    name: '',
                    printingColor: source === 'document'? 'Colour': '4C (Front)',
                    amountDefault: {minQ: 0, amount: 0},
                    amountGreater: {minQ: 0, amount: 0},
                    amountBetween: [],
                    variations: []
                  }))}
                >
                  <div className="btn-inner--text">
                    <span>Add Item</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mac-scrollbar-white">
            <Table className="align-items-center table-flush" size="sm">
              <thead>
                <tr>
                  <th>Name of Material</th>
                  <th>Materials</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  items.length > 0? 
                    items.map((x, i) => {
                      return (
                        <tr key={i} className="">
                          {/* <th scope="row">
                            {i+1}.
                          </th> */}
                          <td className="px-1">
                            <Input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                              bsSize="sm"
                              value={x.name}
                              onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                            />
                            
                            <Input
                              type="select"
                              bsSize="sm"
                              value={x.printingColor}
                              onChange={(e) => itemOnChange(e.target.value, 'printingColor', i)}
                            >
                              {
                                source === 'document'? (
                                  <>
                                    <option value={"Colour"}>
                                      Colour
                                    </option>
                                    <option value={"Black / Grayscale"}>
                                      Black / Grayscale
                                    </option>
                                  </>
                                ): (
                                  <>
                                    <option value={"4C (Front)"}>
                                      4C (Front)
                                    </option>
                                    <option value={"4C (Both)"}>
                                      4C (Both)
                                    </option>
                                    <option value={"1C-Grayscale (Front)"}>
                                      1C-Grayscale (Front)
                                    </option>
                                    <option value={"1C-Grayscale (Both)"}>
                                      1C-Grayscale (Both)
                                    </option>
                                  </>
                                )
                              }
                            </Input>
                          
                          </td>
                          <td>

                            <Card>
                              <CardBody className="p-0">
                                <Table className="align-items-center table-flush" size="sm">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Amount(<span className="text-uppercase">{currency}</span>)</th>
                                      <th>
                                        <Button
                                          color="secondary"
                                          type="button"
                                          size="sm"
                                          onClick={() => pushStickerVar(i)}
                                        >
                                          <div className="btn-inner--text">
                                            <span>Add Line</span>
                                          </div>
                                        </Button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      x.variations.map((x1, i1) => {
                                        return (
                                          <tr key={i1} className="">
                                            <td className="px-1">
                                              <Input
                                                className="form-control"
                                                placeholder="Name"
                                                type="text"
                                                bsSize="sm"
                                                value={x1.name}
                                                onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                              />
                                            </td>
                                            <td className="px-1">
                                              <Input
                                                className="form-control-alternative"
                                                placeholder="Per Big Sticker Amount"
                                                type="number"
                                                bsSize="sm"
                                                value={Number(x1.amount).toString()}
                                                onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                                onWheel={ event => event.currentTarget.blur() }
                                              />
                                            </td>
                                            <td>
                                              <i 
                                              className="ni ni-fat-remove"
                                              style={{fontSize: '1rem', cursor:'pointer'}}
                                              onClick={() => removeChild('variations', i, i1)}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    </tbody>
                                  </Table>
                              </CardBody>
                            </Card>
                          </td>
                          <td>
                            <i 
                            className="ni ni-fat-remove"
                            style={{fontSize: '1rem', cursor:'pointer'}}
                            onClick={() => removeSticker(i)}
                            />
                          </td>
                        </tr>
                      )
                    }): null
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
 
export default DocumentForm;