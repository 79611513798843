import React from "react";
import cloneDeep from "lodash/cloneDeep"

// reactstrap components
import {
  Table,
  Button,
  Input,
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

const AdditionalOptions = ({
  additionOption,
  setAdditionOption,
  currency
}) => {

  const optionOnChange = (val, name, index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][name] = val
    
    setAdditionOption(cloneOption)
  }

  const pushOptionVar = (index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index]['option'].push({ name: '', amount: 0, percent: 0 })
    
    setAdditionOption(cloneOption)
  }

  const childOptionOnChange = (val, child, name, index, index1) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][child][index1][name] = val
    
    setAdditionOption(cloneOption)
  }

  const removeOptionChild = (child, index, index1) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][child].splice(index1, 1)
    
    setAdditionOption(cloneOption)
  }

  const removeOption = (index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption.splice(index, 1)
    
    setAdditionOption(cloneOption)
  }

  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className=" font-weight-bold">
                Additional Option
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => setAdditionOption(additionOption.concat({
                    name: '',
                    calcWith: 'amount',
                    option: []
                  }))}
                >
                  <div className="btn-inner--text">
                    <span>Add Options</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mac-scrollbar-white">
            <Table className="table-flush" size="sm">
              <thead>
                <tr>
                  <th>Label Name</th>
                  <th>Options</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  additionOption.length > 0? 
                    additionOption.map((x, i) => {
                      return (
                        <tr key={i} className="">
                          {/* <th scope="row">
                            {i+1}.
                          </th> */}
                          <td className="px-1">
                            <Input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                              bsSize="sm"
                              value={x.name}
                              onChange={(e) => optionOnChange(e.target.value, 'name', i)}
                            />
                          </td>
                          <td>

                            <Card>
                              <CardBody className="p-0">
                                <Table className="align-items-center table-flush" size="sm">
                                  <thead>
                                    <tr>
                                      <th>Option Name</th>
                                      <th>
                                        <select
                                          className="form-control-alternative p-2"
                                          placeholder="Calc With"
                                          value={x.calcWith}
                                          onChange={(e) => optionOnChange(e.target.value, 'calcWith', i)}
                                        >
                                          <option value="amount">
                                            Amount ({currency})
                                          </option>
                                          <option value="percent">
                                            Percent %
                                          </option>
                                        </select>

                                      </th>
                                      <th>
                                        <Button
                                          color="secondary"
                                          type="button"
                                          size="sm"
                                          onClick={() => pushOptionVar(i)}
                                        >
                                          <div className="btn-inner--text">
                                            <span>Add Line</span>
                                          </div>
                                        </Button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      x.option.map((x1, i1) => {
                                        return (
                                          <tr key={i1} className="">
                                            <td className="px-1">
                                              <Input
                                                className="form-control"
                                                placeholder="Name"
                                                type="text"
                                                bsSize="sm"
                                                value={x1.name}
                                                onChange={(e) => childOptionOnChange(e.target.value, 'option', 'name', i, i1)}
                                              />
                                            </td>
                                            <td className="px-1">
                                              {
                                                x.calcWith === 'percent'? (
                                                  <Input
                                                    className="form-control-alternative"
                                                    placeholder="Percent"
                                                    type="number"
                                                    bsSize="sm"
                                                    value={Number(x1.percent).toString()}
                                                    onChange={(e) => childOptionOnChange(Number(e.target.value), 'option', 'percent', i, i1)}
                                                    onWheel={ event => event.currentTarget.blur() }
                                                  />
                                                ): (
                                                  <Input
                                                    className="form-control-alternative"
                                                    placeholder="Amount"
                                                    type="number"
                                                    bsSize="sm"
                                                    value={Number(x1.amount).toString()}
                                                    onChange={(e) => childOptionOnChange(Number(e.target.value), 'option', 'amount', i, i1)}
                                                    onWheel={ event => event.currentTarget.blur() }
                                                  />
                                                )
                                              }
                                            </td>
                                            <td>
                                              <i 
                                              className="ni ni-fat-remove"
                                              style={{fontSize: '1rem', cursor:'pointer'}}
                                              onClick={() => removeOptionChild('option', i, i1)}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    </tbody>
                                  </Table>
                              </CardBody>
                            </Card>
                          </td>
                          <td>
                            <i 
                            className="ni ni-fat-remove"
                            style={{fontSize: '1rem', cursor:'pointer'}}
                            onClick={() => removeOption(i)}
                            />
                          </td>
                        </tr>
                      )
                    }): null
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

    </>
  );
}
 
export default AdditionalOptions;