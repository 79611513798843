import {
  FETCH_MARGINS,
  PUSH_MARGINS,
  UPDATE_MARGINS,
  REMOVE_MARGINS,
 } from '../actions/marginsActions';

const INITIAL_STATE = {
  data: [],
}

export default function statusReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_MARGINS:
      return {
        ...state,
        data:action.data,
      }
    case PUSH_MARGINS:
      return {
        ...state,
        data:[action.data, ...state.data],
      }
    case UPDATE_MARGINS:
      let updated = state.data.map((v)=>{
        if(v._id === action.data._id) {
          return action.data
        } else return v
      })
      return {
        ...state,
        data: updated
      }
    case REMOVE_MARGINS:
      return {
        ...state,
        data: [...state.data.filter( (item) => item._id !== action.data._id)]
      }
    default:
      return state

  }
}