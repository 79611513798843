import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return ( 
    <>
      <Route {...rest} render={(props) => (
        rest.auth
          ? <Component {...props} />
          : <Redirect to={{
              pathname: '/auth/login'
            }} />
      )} />
    </>
  );
}
 
const mapStateToProps = state => ({
  auth: state.role.auth
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);