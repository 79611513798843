import React from "react";
import { Formik } from 'formik';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col
} from "reactstrap";

import client from "../../feathers.js"

const UserModalEdit = (props) => {

  const onSubmit = async(value) => {
    client.authenticate()
    .then(()=>{
      return client.service('users').patch(props.dataEdit._id, value)
    })
    .then((res) => {
      props.toggleModal()
      res.totalProducts = props.dataEdit.totalProducts
      props.updateUsers(res)
      props.notificationOpen(true, 'success', "User " + res.email + " Edited Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  return (  
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit User
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: props.dataEdit.firstName,
            lastName: props.dataEdit.lastName,
            phoneNumber: props.dataEdit.phoneNumber,
            address: props.dataEdit.address,
            state: props.dataEdit.state,
            country: props.dataEdit.country,
            postalCode: props.dataEdit.postalCode,
            numbOfMargins: props.dataEdit.numbOfMargins,
            numbOfActiveProduct: props.dataEdit.numbOfActiveProduct,
            description: props.dataEdit.description,
            currency: props.dataEdit.currency?props.dataEdit.currency:"myr",
          }}
          onSubmit={onSubmit}
        >
          {propsChild => {
            const {
              values,
              handleChange,
              handleSubmit,
            } = propsChild;
          return (
            <Form role="form" onSubmit={handleSubmit}>
              <div className="modal-body pt-0">
                <Row>
                  <Col lg="12">
                    <label
                      className="form-control-label"
                      htmlFor="Key"
                    >
                      Key
                    </label>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Key"
                          type="text"
                          defaultValue={props.dataEdit._id}
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <label
                      className="form-control-label"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          id="email"
                          defaultValue={props.dataEdit.email}
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="firstName"
                      >
                        First name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="First name"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="lastName"
                      >
                        Last name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="Last name"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="gender"
                      >
                        Gender
                      </label>
                        <Input 
                          className="form-control-alternative"
                          placeholder="Gender"
                          type="select" 
                          id="gender" 
                          value={values.gender}
                          onChange={handleChange}
                        >
                        <option>male</option>
                        <option>female</option>
                        <option>other</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="birth"
                      >
                        Birth
                      </label>
                      <Input 
                        className="form-control-alternative"
                        placeholder="Birth"
                        type="date"
                        id="birth"
                        value={values.birth}
                        onChange={handleChange} 
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="currency"
                      >
                        Currency
                      </label>
                        <Input 
                          className="form-control-alternative text-uppercase"
                          placeholder="Currency"
                          type="select" 
                          id="currency" 
                          value={values.currency}
                          onChange={handleChange}
                        >
                        <option>myr</option>
                        <option>sgd</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="phoneNumber"
                      >
                        Contact
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        placeholder="Contact"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="currency"
                      >
                        Numb Of Margins
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        id="numbOfMargins"
                        value={Number(values.numbOfMargins).toString()}
                        min={3}
                        max={999}
                        onChange={handleChange}
                        placeholder="Number Of Margins"
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="currency"
                      >
                        Numb Of Active Product
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="number"
                        id="numbOfActiveProduct"
                        value={Number(values.numbOfActiveProduct).toString()}
                        min={5}
                        max={999}
                        onChange={handleChange}
                        placeholder="Number Of Active Product"
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-4" />
                {/* Address */}
                {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="address"
                      >
                        Address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="address"
                        value={values.address}
                        onChange={handleChange} 
                        placeholder="Home Address"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="state"
                      >
                        state
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="state"
                        value={values.state}
                        onChange={handleChange} 
                        placeholder="state"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="country"
                        value={values.country}
                        onChange={handleChange} 
                        placeholder="Country"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="postalCode"
                      >
                        Postal code
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="postalCode"
                        value={values.postalCode}
                        onChange={handleChange} 
                        placeholder="Postal code"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-4" /> */}
                {/* Description */}
                  <FormGroup>
                    <label>About User</label>
                    <Input
                      className="form-control-alternative"
                      placeholder="A few words about user ..."
                      rows="4"
                      id="description"
                      value={values.description}
                      onChange={handleChange} 
                      type="textarea"
                    />
                  </FormGroup>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModal}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
 
export default UserModalEdit;