/*eslint-disable*/
import React from "react";
import cloneDeep from "lodash/cloneDeep"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Table,
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

function addPercentToAmount(amount, percent){
  let defaultAmt = (percent / 100) * amount
  return (amount + defaultAmt).toFixed(2)
}

const StickerForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  manualCostBetween,
  setManualCostBetween,
  manualCostGreater,
  setManualCostGreater,
  manualCostDefault,
  setManualCostDefault,
  source,
  currency
}) => {

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', basePrice: 0, amount: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  const ManualBetweenOnChange = (val, name, index) => {
    let cloneManualCostBetween = cloneDeep(manualCostBetween)
    cloneManualCostBetween[index][name] = val
    
    setManualCostBetween(cloneManualCostBetween)
  }

  const removeManualBetween = (index) => {
    let cloneManualCostBetween = cloneDeep(manualCostBetween)
    cloneManualCostBetween.splice(index, 1)
    
    setManualCostBetween(cloneManualCostBetween)
  }

  const addItem = () => {
    setItems(items.concat({
      name: '',
      basePrice: 0,
      amountDefault: {minQ: 0, amount: 0},
      amountGreater: {minQ: 0, amount: 0},
      amountBetween: [],
      variations: []
    }))
  }

  const duplicateItem = (x) => {
    let cloneItems = cloneDeep(items)

    if(x.amountBetween){
      if(x.amountBetween.length > 0){
        x.amountBetween.map(x => {
          delete x._id
          return x
        })
      }
    }

    if(x.variations){
      if(x.variations.length > 0){
        x.variations.map(x => {
          delete x._id
          return x
        })
      }
    }

    cloneItems = cloneItems.concat(x)
    
    setItems(cloneItems)
  }

  return (  
    <>
      <Row>
        <Col md="3">
          <div className="mb-2">
            <small className=" font-weight-bold">
              Base Price (<span className="text-uppercase">{currency}</span>)
            </small>
          </div>
          <FormGroup
            className={classnames("mb-3", {
              focused: amountFocused
            }, {
              "has-danger": errors.netAmount && touched.netAmount
            })}
          >
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-money-coins" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Product Net Amount"
                type="number"
                id="netAmount"
                value={Number(values.netAmount).toString()}
                onChange={handleChange}
                onFocus={e => setAmountFocused(true)}
                onBlur={e => {
                  setAmountFocused(false);
                  handleBlur(e)
                }}
                onWheel={ event => event.currentTarget.blur() }
              />
            </InputGroup>
            {errors.netAmount && touched.netAmount && (
              <div className="input-feedback">{errors.netAmount}</div>
            )}
          </FormGroup>
        </Col>
        {
          source === 'loose-sheet'? (
            <>
            <Col md="3">
              <div className="mb-2">
                <small className=" font-weight-bold">
                  Folding Percent
                </small>
              </div>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-percent" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Folding Percent"
                    type="number"
                    id="foldingPercent"
                    value={Number(values.foldingPercent).toString()}
                    onChange={handleChange}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
             <Col md="3">
             <div className="mb-2">
               <small className=" font-weight-bold">
                 Folding Base Price (<span className="text-uppercase">{currency}</span>)
               </small>
             </div>
             <FormGroup>
               <InputGroup className="input-group-alternative">
                 <InputGroupAddon addonType="prepend">
                   <InputGroupText>
                     <i className="ni ni-money-coins" />
                   </InputGroupText>
                 </InputGroupAddon>
                 <Input
                   placeholder="Folding Percent"
                   type="number"
                   id="foldingBase"
                   value={Number(values.foldingBase).toString()}
                   onChange={handleChange}
                   onWheel={ event => event.currentTarget.blur() }
                 />
               </InputGroup>
             </FormGroup>
           </Col>
           <Col md="3">
          <div className="mb-2">
            <small className="font-weight-bold">
              Double Side (exp: 1.3)
            </small>
          </div>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Double Side Percent (exp: 1.3)"
                type="number"
                id="doubleSidePercent"
                value={Number(values.doubleSidePercent).toString()}
                onChange={handleChange}
                onWheel={ event => event.currentTarget.blur() }
              />
            </InputGroup>
          </FormGroup>
        </Col>
           </>
          ): null
        }
      </Row>

      <hr className="mt-3" />
      <Row className="align-items-center mb-1">
        <Col md="2">
          <small className="font-weight-bold">Max Print Size</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="widthRange.max"
            bsSize="sm"
            value={Number(values.widthRange.max).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Width)</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="heightRange.max"
            bsSize="sm"
            value={Number(values.heightRange.max).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Height)</small>
        </Col>
        <Col md="4" className="pl-1 pr-0">
          <p className="font-weight-bold mb-0">Excluding Bleeding</p>
        </Col>
      </Row>
      <Row className="align-items-center mb-1">
        <Col md="2">
          <small className="font-weight-bold">Min Print Size</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="widthRange.min"
            bsSize="sm"
            value={Number(values.widthRange.min).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Width)</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="heightRange.min"
            bsSize="sm"
            value={Number(values.heightRange.min).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Height)</small>
        </Col>
        <Col md="4" className="pl-1 pr-0">
          <p className="font-weight-bold mb-0">Excluding Bleeding</p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md="2">
          <small className="font-weight-bold">Bleeding</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="bleeding.width"
            bsSize="sm"
            value={Number(values.bleeding.width).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Width)</small>
        </Col>
        <Col md="1" className="p-0">
          <Input
            className="form-control-alternative"
            placeholder="0"
            type="number"
            id="bleeding.height"
            bsSize="sm"
            value={Number(values.bleeding.height).toString()}
            onChange={handleChange}
            onWheel={ event => event.currentTarget.blur() }
          />
        </Col>
        <Col md="2" className="pl-1 pr-0">
          <small className="font-weight-bold">mm (Height)</small>
        </Col>
      </Row>
      <hr />

      <Card className="shadow mb-4">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className="text-capitalize font-weight-bold">
                Materials
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => addItem()}
                >
                  <div className="btn-inner--text">
                    <span>Add Item</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="">
            
                {
                  items.length > 0? 
                    items.map((x, i) => {
                      return (
                        <Card key={i} className="shadow mb-4">
                          <CardBody>
                            {/* <th scope="row">
                              {i+1}.
                            </th> */}
                            <Row className="align-items-center">
                              <Col md="5">
                                <small className="font-weight-bold">Name of Material</small>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Name"
                                  type="text"
                                  bsSize="sm"
                                  value={x.name}
                                  onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                                />
                              </Col>
                              <Col md="3">
                                <small className="font-weight-bold">Base Price (<span className="text-uppercase">{currency}</span>)</small>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Base Price"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.basePrice).toString()}
                                  onChange={(e) => itemOnChange(Number(e.target.value), 'basePrice', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </Col>
                              <Col md="4">
                                <div className="d-flex justify-content-end align-items-center">
                                  <Button
                                    color="warning"
                                    type="button"
                                    size="sm"
                                    onClick={() => duplicateItem(x)}
                                  >
                                    <div className="btn-inner--text">
                                      <span>Duplicate</span>
                                    </div>
                                  </Button>
                                  <span>
                                    <i 
                                    className="ni ni-fat-remove"
                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                    onClick={() => removeSticker(i)}
                                    />
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <hr />
                            <div>
                              <Row className="mb-2">
                                <Col md="12" lg="6">
                                  <small className="font-weight-bold">
                                    Printing Cost
                                  </small>
                                  <Table style={{tableLayout: 'fixed'}} className="align-items-center table-flush" size="sm">
                                    <thead>
                                      <tr>
                                        <th style={{paddingLeft: 0, paddingRight: '1.5rem'}}>Min Quantity</th>
                                        <th style={{paddingLeft: '1rem', paddingRight: '1rem'}}>Max Quantity</th>
                                        <th><span className="text-uppercase">{currency}</span>/Sheet</th>
                                        <th>
                                          <Button
                                            color="secondary"
                                            type="button"
                                            size="sm"
                                            onClick={() => itemOnChange(x.amountBetween.concat({
                                              minQ: 0,
                                              maxQ: 0,
                                              amount: 0
                                            }), 'amountBetween', i)}
                                          >
                                            <div className="btn-inner--text">
                                              <span>Add Line</span>
                                            </div>
                                          </Button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="px-1">
                                          <Input
                                            className="form-control"
                                            placeholder="Min Q"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountDefault.minQ).toString()}
                                            onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountDefault.amount}, 'amountDefault', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td>{"<"}</td>
                                        <td className="px-1">
                                          <Input
                                            className="form-control-alternative"
                                            placeholder="Amount"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountDefault.amount).toString()}
                                            onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amount: Number(e.target.value)}, 'amountDefault', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                        {
                                          x.amountBetween.length > 0? 
                                            x.amountBetween.map((x1, i1) => {
                                              return (
                                                <tr key={i1} className="">
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Min Q"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.minQ).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'minQ', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Max Q"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.maxQ).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'maxQ', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control-alternative"
                                                      placeholder="Amount"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.amount).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'amount', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td>
                                                    <i 
                                                    className="ni ni-fat-remove"
                                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                                    onClick={() => removeChild('amountBetween', i, i1)}
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            }): null
                                        }
                                      <tr className="">
                                        <td className="px-1">
                                          <Input
                                            className="form-control"
                                            placeholder="Min Q"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountGreater.minQ).toString()}
                                            onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountGreater.amount}, 'amountGreater', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td>{">"}</td>
                                        <td className="px-1">
                                          <Input
                                            className="form-control-alternative"
                                            placeholder="Amount"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountGreater.amount).toString()}
                                            onChange={(e) => itemOnChange({minQ: x.amountGreater.minQ, amount: Number(e.target.value)}, 'amountGreater', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col md="12" lg="6">
                                  <small className="font-weight-bold">
                                    Variation Cost
                                  </small>
                                  <Card>
                                    <CardBody className="p-0">
                                      <Table style={{tableLayout: 'fixed'}} className="align-items-center table-flush" size="sm">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th className="p-0">Base Price(<span className="text-uppercase">{currency}</span>)</th>
                                            <th><span className="text-uppercase">{currency}</span>/Sheet</th>
                                            <th>
                                              <Button
                                                color="secondary"
                                                type="button"
                                                size="sm"
                                                onClick={() => pushStickerVar(i)}
                                              >
                                                <div className="btn-inner--text">
                                                  <span>Add Line</span>
                                                </div>
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            x.variations.map((x1, i1) => {
                                              return (
                                                <tr key={i1} className="">
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Name"
                                                      type="text"
                                                      bsSize="sm"
                                                      value={x1.name}
                                                      onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Base Price"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.basePrice).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'basePrice', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control-alternative"
                                                      placeholder="Per Big Sticker Amount"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.amount).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td>
                                                    <i 
                                                    className="ni ni-fat-remove"
                                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                                    onClick={() => removeChild('variations', i, i1)}
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          }
                                          </tbody>
                                        </Table>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      )
                    }): null
                }
                
                
          </div>
        </CardBody>
      </Card>

      {
        source !== 'loose-sheet'? (
          <Card className="shadow mb-4">
            <CardBody>
              <Row className="mb-2">
                {/* <Col>
                  <small className=" font-weight-bold">
                    Cutting Cost
                  </small>
                </Col> */}
                {/* <Col>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="secondary"
                      type="button"
                      size="sm"
                      onClick={() => setManualCostBetween(manualCostBetween.concat({
                        minQ: 0,
                        maxQ: 0,
                        amount: 0
                      }))}
                    >
                      <div className="btn-inner--text">
                        <span>Add Condition</span>
                      </div>
                    </Button>
                  </div>
                </Col> */}
              </Row>
              <Row className="mb-2">
                <Col md="3">
                  <div className="mb-2">
                    <small className=" font-weight-bold">
                      Custom Shape Percent (%)
                    </small>
                  </div>
                  <FormGroup>
                    <Input
                      className="form-control-alternative"
                      placeholder="Product Net Amount"
                      type="number"
                      id="manualPercent"
                      bsSize="sm"
                      value={Number(values.manualPercent).toString()}
                      onChange={handleChange}
                      onWheel={ event => event.currentTarget.blur() }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <div className="table-responsive mac-scrollbar-white">
                <Table className="align-items-center table-flush" size="sm">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Min Q.</th>
                      <th>Max Q.</th>
                      <th></th>
                      <th>Amount</th>
                      <th>Amount<br/>(Custom)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <th scope="row">
                        1.
                      </th>
                      <td className="px-1">
                        <Input
                          className="form-control"
                          placeholder="Min Q"
                          type="number"
                          bsSize="sm"
                          value={Number(manualCostDefault.minQ).toString()}
                          onChange={(e) => setManualCostDefault({minQ: e.target.value, amount: manualCostDefault.amount})}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </td>
                      <td>{"<"}</td>
                      <td></td>
                      <td className="px-1">
                        <Input
                          className="form-control-alternative"
                          placeholder="Amount"
                          type="number"
                          bsSize="sm"
                          value={Number(manualCostDefault.amount).toString()}
                          onChange={(e) => setManualCostDefault({minQ: manualCostDefault.minQ, amount: Number(e.target.value)})}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </td>
                      <td className="px-1">
                        <Input
                          className="form-control-alternative"
                          placeholder="Amount"
                          type="number"
                          bsSize="sm"
                          value={Number(addPercentToAmount(manualCostDefault.amount, values.manualPercent)).toString()}
                          onWheel={ event => event.currentTarget.blur() }
                          disabled
                        />
                      </td>
                    </tr>
                    {
                      manualCostBetween.length > 0? 
                        manualCostBetween.map((x, i) => {
                          return (
                            <tr key={i} className="">
                              <th scope="row">
                                {(i+1)+1}.
                              </th>
                              <td className="px-1">
                                <Input
                                  className="form-control"
                                  placeholder="Min Q"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.minQ).toString()}
                                  onChange={(e) => ManualBetweenOnChange(Number(e.target.value), 'minQ', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </td>
                              <td className="px-1">
                                <Input
                                  className="form-control"
                                  placeholder="Amount Charge"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.maxQ).toString()}
                                  onChange={(e) => ManualBetweenOnChange(Number(e.target.value), 'maxQ', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </td>
                              <td></td>
                              <td className="px-1">
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Amount"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.amount).toString()}
                                  onChange={(e) => ManualBetweenOnChange(Number(e.target.value), 'amount', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </td>
                              <td className="px-1">
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Amount"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(addPercentToAmount(x.amount, values.manualPercent)).toString()}
                                  onWheel={ event => event.currentTarget.blur() }
                                  disabled
                                />
                              </td>
                              <td>
                                <i 
                                className="ni ni-fat-remove"
                                style={{fontSize: '1rem', cursor:'pointer'}}
                                onClick={() => removeManualBetween(i)}
                                />
                              </td>
                            </tr>
                          )
                        }): null
                    }
                    <tr className="">
                      <th scope="row">
                        {manualCostBetween.length+2}.
                      </th>
                      <td className="px-1">
                        <Input
                          className="form-control"
                          placeholder="Min Q"
                          type="number"
                          bsSize="sm"
                          value={Number(manualCostGreater.minQ).toString()}
                          onChange={(e) => setManualCostGreater({minQ: e.target.value, amount: manualCostGreater.amount})}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </td>
                      <td>{">"}</td>
                      <td></td>
                      <td className="px-1">
                        <Input
                          className="form-control-alternative"
                          placeholder="Amount"
                          type="number"
                          bsSize="sm"
                          value={Number(manualCostGreater.amount).toString()}
                          onChange={(e) => setManualCostGreater({minQ: manualCostGreater.minQ, amount: Number(e.target.value)})}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </td>
                      <td className="px-1">
                        <Input
                          className="form-control-alternative"
                          placeholder="Amount"
                          type="number"
                          bsSize="sm"
                          value={Number(addPercentToAmount(manualCostGreater.amount, values.manualPercent)).toString()}
                          onWheel={ event => event.currentTarget.blur() }
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div> */}
            </CardBody>
          </Card>
        ): null
      }
    </>
  );
}
 
export default StickerForm;