import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  // Modal,
  Row,
  Col
} from "reactstrap";

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import MarginCalc from "../Products/MarginCalc"

import client from "../../feathers.js"

const validationSchema = Yup.object().shape({
  marginName: Yup.string()
    .required('Algorithm Name is required'),
})

const MarginsModalEdit = (props) => {
  const [ marginBetween, setMarginBetween ] = useState([])
  const [ marginFocused, setMarginFocused ] = useState(false)
  
  useEffect(() => {
    if(!isEmpty(props.dataEdit)){
      setMarginBetween(props.dataEdit.marginBetween)
    }
  }, [props.modalVisible, props.dataEdit])

  const marginBetweenOnChange = (val, name, index) => {
    let cloneMarginBetween = cloneDeep(marginBetween)
    cloneMarginBetween[index][name] = val
    
    setMarginBetween(cloneMarginBetween)
  }

  const removeMarginBetween = (index) => {
    let cloneMarginBetween = cloneDeep(marginBetween)
    cloneMarginBetween.splice(index, 1)
    
    setMarginBetween(cloneMarginBetween)
  }

  const onSubmit = async(value) => {

    value.marginBetween = marginBetween

    client.authenticate()
    .then(()=>{
      return client.service('margins').patch(props.dataEdit._id, value)
    })
    .then((res) => {
      props.toggleModal()
      props.updateMargins(res)
      props.notificationOpen(true, 'success', "Margin " + res.marginName + " Edited Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  return (  
    <>
      {/* <Modal
        className="modal-dialog-centered status-modal"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      > */}
      <Drawer
        open={props.modalVisible}
        onClose={props.toggleModal}
        className='shadow-lg overflow-auto mac-scrollbar'
        direction='right'
        enableOverlay={false}
        size="81%"
      >
        <div className="px-4 py-2 d-flex align-items-center">
          <button
            aria-label="Close"
            className="close float-none"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true} style={{fontSize: '2rem', height: '2.25rem', width: '2.25rem', lineHeight: '2.8rem'}}>
              <i className="ni ni-fat-remove"/>
            </span>
            </button>
          <h4 className="modal-title">
            Edit Algorithm
          </h4>
        </div>
        <hr className="mt-0 mb-2"/>
        <Formik
          enableReinitialize
          initialValues={{
            marginName: props.dataEdit.marginName,
            description: props.dataEdit.description,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {propsChild => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = propsChild;
          return (
            <Form role="form" onSubmit={handleSubmit}>
              <div className="modal-body pt-0">
                <Row>
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="marginName"
                    >
                      Algorithm Name
                    </label>
                    <FormGroup className={classnames({
                        focused: marginFocused
                      }, {
                        "has-danger": errors.marginName && touched.marginName
                      })}>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          placeholder="Algorithm Name"
                          type="text"
                          id="marginName"
                          value={values.marginName}
                          onChange={handleChange}
                          onFocus={e =>
                            setMarginFocused(true)
                          }
                          onBlur={e => {
                            setMarginFocused(false)
                            handleBlur(e)
                          }} 
                        />
                      </InputGroup>
                      {errors.marginName && touched.marginName && (
                        <div className="input-feedback">{errors.marginName}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Description
                        </small>
                      </div>
                      <Input
                        className="form-control-alternative"
                        placeholder="Description"
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        autoComplete="off"
                        type="textarea"
                        rows="3"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <MarginCalc 
                  marginBetween={marginBetween}
                  setMarginBetween={setMarginBetween}
                  marginBetweenOnChange={marginBetweenOnChange}
                  removeMarginBetween={removeMarginBetween}
                  marginsData={props.marginsData}
                  currency={props.currency}
                />
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModal}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Drawer>
      {/* </Modal> */}
    </>
  );
}
 
export default MarginsModalEdit;