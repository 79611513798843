
// mails to
const suppliers = [
  'ec',
  'xp',
  'pe',
  'ep',
  'mem',
  'other'
]

const role = "main" // main, sup

const env = {
  suppliers: suppliers,
  role: role
}

export default env;