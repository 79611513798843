import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchMargins, pushMargins, updateMargins, removeMargins } from '../redux/actions/marginsActions';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import MarginsModal from "../components/Margins/MarginsModal.js"
import MarginsModalEdit from "../components/Margins/MarginsModalEdit.js"
import ModalConfirmation from "../components/Extra/ModalConfirmation.js"

import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"
import axios from "axios"

const PAGESIZE = 20;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Margins = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    fetchData(props.userId, props.fetchMargins, notificationOpen, 0, 1)
  }, [props.userId, props.fetchMargins])

  const toggleModal = () => {

    if(props.role !== 'superadmin'){
      // If not superadmin
      // Check numbOfMargins
      let numbOfMargins = props.userInfo.numbOfMargins?props.userInfo.numbOfMargins:3
      if(props.marginsData.length < numbOfMargins){
        setMmodalVisible(!modalVisible)
      }else{
        notificationOpen(true, 'danger', "Exceed Number of Algorithm!")
      }
    }else{
      setMmodalVisible(!modalVisible)
    }
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    client.authenticate()
    .then((auth)=>{
      return axios.post(`${client.io.io.uri}deleteMarginAndPatchProductsMargin`, {
        role: props.role,
        marginId: dataEdit._id
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      // console.log(res)
      props.removeMargins({
        _id: dataEdit._id
      })
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchMargins, notificationOpen, skip, currentPage) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('margins').find({
          query: {
            userId: userId,
            $skip: skip,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage))
        fetchMargins(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchMargins, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchMargins, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header pb-6 pt-4 pt-md-7">
        <span className="mask" style={{backgroundColor: '#fff'}} />
        <Container className="align-items-center" fluid>
          {
            props.role === 'superadmin'? (
              <Row>
                <Col md="12">
                  <h1 className="display-3 text-uppercase">{props.currency}</h1>
                </Col>
              </Row>
            ): (
              <Row>
                <Col md="2">
                  <span className="font-weight-bold">Currency</span>
                  <h1 className="display-3 text-uppercase">{props.currency}</h1>
                </Col>
                <Col md="4">
                  <span className="font-weight-bold">Max. Available Algorithm</span>
                  <h1 className="display-3">{props.userInfo.numbOfMargins?props.userInfo.numbOfMargins:3}</h1>
                </Col>
                <Col md="4">
                  <span className="font-weight-bold">Utilised</span>
                  <h1 className="display-3">{props.marginsData.length}</h1>
                </Col>
              </Row>
            )
          }
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Algorithm tables</h3>
                  </Media>
                  <Media body className="text-right">
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--text">Create</span>
                    </Button>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush" style={{minHeight: 200}}>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Created</th>
                      <th scope="col">Description</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props.marginsData.length > 0? (
                      props.marginsData.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {v.marginName}
                            </td>
                            <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td>{v.description}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      setDataEdit(v)
                                      toggleModalConfirm()
                                    }}
                                  >
                                      Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          )
                        })
                      ): (
                        <tr>
                          <td>
                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                          </td>
                        </tr>
                        )
                      }
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <MarginsModal 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
        notificationOpen={notificationOpen} 
        pushMargins={props.pushMargins}
        marginsData={props.marginsData}
        currency={props.currency}
      />
      <MarginsModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen} 
        updateMargins={props.updateMargins}
        marginsData={props.marginsData}
        currency={props.currency}
      />
      <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title="Delete Margin"
        content={(
          <div className="text-center">
            <div className="text-danger mb-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
            </div>
            <h3>Are you sure want delete this Margin?</h3>
            <hr style={{ marginTop: '1rem', marginBottom: '1rem', width: '80%'}}/>
            <p className="font-weight-400 text-muted"><strong>Product's Margin</strong> will be removed also those linked to this <strong>Algorithm</strong>!</p>
          </div>
        )}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
  marginsData: state.margins.data,
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  fetchMargins: fetchMargins,
  pushMargins: pushMargins,
  updateMargins: updateMargins,
  removeMargins: removeMargins,
};
export default connect(mapStateToProps, mapDispatchToProps)(Margins);