import React from "react";
import { Field, FieldArray } from 'formik';

// reactstrap components
import {
  Table,
  Button,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";

const CSVForm = ({
  csvFile, 
  uploadFileEl, 
  handleFileChange, 
  progress, 
  setCsvFile,
  values,
  currency
}) => {
  return (  
    <>
      <Row>
        <Col md="6">
          <div className="mb-2">
            <small className=" font-weight-bold">
              Upload Data File
            </small>
          </div>
          <FormGroup className="text-center">
            {
              !csvFile.name && !csvFile.fileName? (
                <div className="file-upload">
                  <input 
                    type="file" 
                    ref={uploadFileEl}
                    accept=".csv" 
                    onChange={(e) => handleFileChange(e)} 
                    hidden />                
                    <div className="progessBar" style={{ width: progress }}>
                  </div>
                  <Button
                    className="m-0 mb-1 w-100"
                    color="default"
                    onClick={() => uploadFileEl.current.click()}
                  >
                    <div className="btn-inner--icon">
                      <span className="btn-inner--text">Upload File</span>
                    </div>
                  </Button>
                </div>
              ): (
                <Row>
                  <Col md="8 pr-0">
                    <Input
                      type="text"
                      value={csvFile.name || csvFile.fileName}
                      disabled
                    />
                  </Col>
                  {
                    values.source !== 'csv-prefix'? (
                      <Col md="4 pl-0">
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => setCsvFile({})}
                        >
                          Remove
                        </Button>
                      </Col>
                    ): null
                  }
                </Row>
              )
            }
          </FormGroup>
        </Col>
        {/* <Col md="6">
          <div className="mb-2">
            <small className=" font-weight-bold">
              Source Code Filter By
            </small>
          </div>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-file-excel"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="CSV Code Filter"
                type="text"
                id="sourceFilterBy"
                rows="3"
                value={values.sourceFilterBy}
                onChange={handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Col> */}
      </Row>
      <Row>
        <Col md="6">
          <div className="">
            <small className=" font-weight-bold">
              Rounded Corners Options
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Filter by the first column of CSV file
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="roundedOptions"
              render={arrayHelpers => (
                <div>
                  {values.roundedOptions && values.roundedOptions.length > 0 ? (
                    values.roundedOptions.map((rounded, index) => (
                      <div key={index}>
                        <Row className="align-items-center">
                          <Col md="8">
                            <Field className="form-control" name={`roundedOptions.${index}`} />
                          </Col>
                          <Col md="4">
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                      Add Model
                    </Button>
                  )}
                </div>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <div className="">
            <small className=" font-weight-bold">
              Punch Hole Options
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Filter by the first column of CSV file
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="punchHoleOptions"
              render={arrayHelpers => (
                <div>
                  {values.punchHoleOptions && values.punchHoleOptions.length > 0 ? (
                    values.punchHoleOptions.map((punchhole, index) => (
                      <div key={index}>
                        <Row className="align-items-center">
                          <Col md="8">
                            <Field className="form-control" name={`punchHoleOptions.${index}`} />
                          </Col>
                          <Col md="4">
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                      Add Model
                    </Button>
                  )}
                </div>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <div className="">
            <small className=" font-weight-bold">
              Hotstamp Options
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Filter by the first column of CSV file
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="hotstampOptions"
              render={arrayHelpers => (
                <div>
                  {values.hotstampOptions && values.hotstampOptions.length > 0 ? (
                    values.hotstampOptions.map((punchhole, index) => (
                      <div key={index}>
                        <Row className="align-items-center">
                          <Col md="8">
                            <Field className="form-control" name={`hotstampOptions.${index}`} />
                          </Col>
                          <Col md="4">
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                      Add Model
                    </Button>
                  )}
                </div>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="">
            <small className=" font-weight-bold">
              Additional Data
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Filter by the first column of CSV file
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="additionalData"
              render={arrayHelpers => (
                <>
                <div className="table-responsive mac-scrollbar-white">
                  <Table className="align-items-center table-flush" size="sm">
                    <thead>
                      <tr>
                        <th>Column Name</th>
                        <th>Modal</th>
                        <th>Column Data</th>
                        <th>Split Comma</th>
                        <th>Cost Price(<span className="text-uppercase">{currency}</span>)</th>
                        <th>
                          <Button 
                            color="secondary"
                            type="button"
                            size="sm"
                            onClick={() => arrayHelpers.push({ 
                              name: '', 
                              columnName: '', 
                              data: '',
                              split: false,
                              price: 0,
                            })}
                          >
                            Add Line
                          </Button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.additionalData.map((additional, index) => (
                        <tr key={index} className="px-1">
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              placeholder="Column Name"
                              type="text"
                              name={`additionalData[${index}].columnName`} />
                          </td>
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              placeholder="Model Name"
                              type="text"
                              name={`additionalData[${index}].name`} />
                          </td>
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              placeholder="Data"
                              type="text"
                              name={`additionalData.${index}.data`} />
                          </td>
                          <td className="px-1">
                            <label className="custom-toggle custom-toggle-primary">
                              <Field 
                                type="checkbox" 
                                checked={additional.split}
                                name={`additionalData.${index}.split`}
                              />
                              <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON"></span>
                            </label>
                          </td>
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              placeholder="Cost Price"
                              type="number"
                              onWheel={ event => event.currentTarget.blur() }
                              name={`additionalData.${index}.price`} />
                          </td>
                          <td className="px-1">
                            <div className="d-flex justify-content-end">
                              <Button 
                                color="primary"
                                type="button"
                                size="sm" 
                                onClick={() => arrayHelpers.remove(index)}>
                                -
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                </>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* <Row>
      <Col md="6">
          <div className="">
            <small className=" font-weight-bold">
              Split Options
            </small>
          </div>
          <div className="">
            <small className="text-muted">
              *Split to options from column title
            </small>
          </div>
          <div className="">
            <small className="text-muted">
              *exp: White, Black, Blue will split to options
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Not work on first colum*
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="splitOptions"
              render={arrayHelpers => (
                <div>
                  {values.splitOptions && values.splitOptions.length > 0 ? (
                    values.splitOptions.map((split, index) => (
                      <div key={index}>
                        <Row className="align-items-center">
                          <Col md="8">
                            <Field className="form-control" name={`splitOptions.${index}`} />
                          </Col>
                          <Col md="4">
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                      Add Title
                    </Button>
                  )}
                </div>
              )}
            />
          </FormGroup>
        </Col>
      </Row> */}
    </>
  );
}
 
export default CSVForm;