import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import findIndex from "lodash/findIndex"

// reactstrap components
import {
  Label,
  Button,
  FormGroup,
  Input,
  Container,
} from "reactstrap";

import client from "../feathers"
import axios from "axios";

const Template = () => {
  const params = useParams();
  const [ data, setData ] = useState([])
  const [ selectIndex, setSelectIndex ] = useState(0)
  const [ typeIndex, setTypeIndex ] = useState(0)

  useEffect(() => {
    client.service('template-downloads').get(params.id)
    .then((res) => {
      if(res.csvFile.fileName){
        axios({
          method: 'post',
          url: `${client.io.io.uri}getCSVTemplateData`,
          data: {fileName: res.csvFile.fileName},
          config: { headers: {'Content-Type': 'application/json' }}
        })
        .then((res) => {

          if(res.data.data.length > 0){
            res.data.data.shift()
      
            let getTitle = []
            
            res.data.data.map(x => {
              if(x[1]){
                getTitle.push({
                  title: x[1],
                  data: []
                })
              }
              return x
            })
      
            let index = 0
            res.data.data.map(x => {
              if(x[1]){
                let findTitle = findIndex(getTitle, e => e.title === x[1])
                index = findTitle
                getTitle[findTitle].data.push({
                  type: x[2],
                  file: x[3],
                  link: x[4]
                })
              }else{
                getTitle[index].data.push({
                  type: x[2],
                  file: x[3],
                  link: x[4]
                })
              }
              return x
            })
            
            setData(getTitle)
          }

        })
        .catch((err) => {
          console.log(err)
        })
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  })

  return (  
    <>
      <Container>
        <FormGroup>
          <Label for="productSelect">
            Product
          </Label>
          <Input
            id="productSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setSelectIndex(e.target.value)
              setTypeIndex(0)
            }}
          >
            {
              data.length > 0?
              data.map((x, i) => {
                return (
                  <option key={i} value={i}>
                    {x.title}
                  </option>
                )
              }): (
                <option>
                </option>
              )
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="typeSelect">
            Type
          </Label>
          <Input
            id="typeSelect"
            name="select"
            type="select"
            value={typeIndex}
            onChange={(e) => {
              setTypeIndex(e.target.value)
            }}
          >
            {
              data.length > 0?
              data[selectIndex].data.length > 0?
                data[selectIndex].data.map((x, i) => {
                  return (
                    <option key={i} value={i}>
                      {x.type}
                    </option>
                  )
                })
              : (
                <option>
                </option>
                )
              : (
                <option>
                </option>
              )
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="typeSelect">
            File Type
          </Label>
          <Input
            id="typeSelect"
            name="select"
            type="select"
            defaultValue={
              data.length > 0? 
                data[selectIndex].data.length > 0?
                  data[selectIndex].data[typeIndex]?
                  data[selectIndex].data[typeIndex].file:
                  ''
                :''
              :''
            }
          >
            {
              data.length > 0?
              data[selectIndex].data.length > 0?
                data[selectIndex].data[typeIndex]?
                  (
                  <option>
                    {data[selectIndex].data[typeIndex].file}
                  </option>
                  )
                : (
                  <option>
                  </option>
                  )
              : (
                <option>
                </option>
                )
              : (
                <option>
                </option>
              )
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="linkSelect">
            Download Link
          </Label>
          <Input
            id="linkSelect"
            type="text"
            defaultValue={
              data.length > 0? 
                data[selectIndex].data.length > 0?
                  data[selectIndex].data[typeIndex]?
                  data[selectIndex].data[typeIndex].link:
                  ''
                :''
              :''
            }
          />
        </FormGroup>
        <FormGroup className='text-center'>
          <Button 
            color='primary'
            href={
              data.length > 0? 
                data[selectIndex].data.length > 0?
                  data[selectIndex].data[typeIndex]?
                  data[selectIndex].data[typeIndex].link:
                  ''
                :''
              :''
            }
            target="_blank"
            rel="noreferrer"
          >
            Download Link
          </Button>
        </FormGroup>
      </Container>
    </>
  );
}
 
export default Template;