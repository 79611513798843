import React, { useState } from "react";
import { Formik, Field } from 'formik';
import cloneDeep from "lodash/cloneDeep"
import isEmpty from "lodash/isEmpty"

// reactstrap components
import {
  FormGroup,
  Label,
  Button,
  Form,
  Modal,
} from "reactstrap";

import client from "../../feathers.js"

const AssignProducts = (props) => {
  const [ loading, setLoading ] = useState(false)

  const onSubmit = async(value) => {
    if(value.userId_selected){
      setLoading(true)
      let processInsert = await props.checkboxChecked.map(async(x) => {
        let r = await props.productsData.map(async(x1) => {
          if(x === x1._id){
            return await new Promise(resolve => {
              let cloneData = cloneDeep(x1)
              delete cloneData._id
              delete cloneData.__v
              delete cloneData.updatedAt
              delete cloneData.updatedBy
              delete cloneData.userId
              delete cloneData.createdAt
              delete cloneData.createdBy
              delete cloneData.productImg
              
              delete cloneData.status

              cloneData.timelines.map(x => {
                delete x._id
                return x
              })
              if(cloneData.marginSuperBetween){
                cloneData.marginSuperBetween.map(x => {
                  delete x._id
                  return x
                })
              }
              if(cloneData.manualCostBetween){
                cloneData.manualCostBetween.map(x => {
                  delete x._id
                  return x
                })
              }

              if(cloneData.additionOption){
                if(cloneData.additionOption.length > 0){
                  cloneData.additionOption.map(x => {
                    delete x._id
                    x.option.map(x1 => {
                      delete x1._id
                      return x1
                    })
                    return x
                  })
                }
              }

              if(cloneData.items){
                if(cloneData.items.length > 0){
                  cloneData.items.map(x => {
                    delete x._id
                    x.amountBetween.map(x1 => {
                      delete x1._id
                      return x1
                    })
                    x.variations.map(x1 => {
                      delete x1._id
                      return x1
                    })
                    return x
                  })
                }
              }

              if(isEmpty(cloneData.csvFile)){
                cloneData.csvFile = {
                  fileName: '',
                  fileUrl: '',  
                }
              }

              cloneData.source = cloneData.source + "-prefix"
              cloneData.userId = value.userId_selected
              cloneData.assignProduct = true

              client.authenticate()
              .then(()=>{
                return client.service('products').create(cloneData)
              })
              .then((res) => {
                resolve(cloneData)
              })
              .catch((err)=>{
                console.log(err.message)
              })
            })
          }else{
            return x1
          }
        })
        if(r){
          return x
        }
      })
      
      const result = await Promise.all(processInsert)
      if(result){
        setLoading(false)
        props.toggleModal()
        props.notificationOpen(true, 'success', "Product Assigned Successfully")
      }
    }
  }

  return (  
    <>
      <Modal
        className="modal-dialog-centered status-modal"
        isOpen={props.modalVisible}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Assign Products to
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            userId_selected: ""
          }}
          onSubmit={onSubmit}
        >
          {propsChild => {
            const {
              handleSubmit,
            } = propsChild;
          return (
            <Form role="form" onSubmit={handleSubmit}>
              <div className="modal-body">
                <FormGroup>
                  <Label for="userId_selected">
                    Assign to User
                  </Label>
                  <Field 
                    as="select" 
                    name="userId_selected" 
                    className="form-control"
                  >
                    <option value={""}>
                      --
                    </option>
                    {
                      props.userList.length > 0?
                        props.userList.map((x, i) => {
                          return (
                            <option key={i} value={x._id}>
                              {x.username} ({x.firstName + " " + x.lastName})
                            </option>
                          )
                        })
                      : null
                    }
                  </Field>
                </FormGroup>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggleModal}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading? (
                      <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ):null}
                    Confirm
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
 
export default AssignProducts;