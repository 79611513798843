import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router'
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { logout } from '../../redux/actions/roleActions';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Form,
  // FormGroup,
  // InputGroupAddon,
  // InputGroupText,
  // Input,
  // InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import client from "../../feathers"

const AdminNavbar = (props) => {
  const [ auth, setAuth ] = useState(false)
  
  useEffect(() => {
    function checkAuth(logout){
      client.authenticate()
      .catch((err) => {
        if(err.name === "NotAuthenticated"){
          logout()
        }
      })
    }

    if(!auth){
      setAuth(true)
      checkAuth(props.logout)
    }
  }, [auth, props.logout])

  const logout = () => {
    client.authenticate()
    .then((a) => {
      client.logout()
      props.logout()
      props.history.push('/auth/login')
    })
    .catch((err) => {
      props.logout()
      props.history.push('/auth/login')
    })
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-uppercase d-none d-md-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle"> */}
                    {/* <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/team-4-800x800.jpg")
                          .default
                      }
                    /> */}
                    {/* <div
                      className="img rounded-circle overflow-hidden bg-white d-inline-block"
                      style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                    >
                      <img
                        alt="entreship sol"
                        className="w-100"
                        src={(props.userInfo.profileImage?props.userInfo.profileImage.fileUrl: "")? props.userInfo.profileImage.fileUrl: "ship.png"}
                      />
                    </div> */}
                  {/* </span> */}
                  <Media className="ml-2 d-none d-md-block">
                    <i className="ni ni-settings-gear-65 text-dark mr-1" />
                    <span className="mb-0 text-sm font-weight-bold text-dark">
                      {
                        props.userInfo.name?props.userInfo.name:
                        (
                          (props.userInfo.firstName?props.userInfo.firstName:"")
                          + " " + 
                          (props.userInfo.lastName?props.userInfo.lastName:"")
                          + " "
                        )
                      }
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {
                  props.role === 'superadmin'? (
                    <DropdownItem to={"/superadmin/user-profile"} tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                  ): (
                    <DropdownItem to={"/admin/user-profile"} tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                  )
                }
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};


const mapStateToProps = state => ({
  auth: state.role.auth,
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminNavbar));
