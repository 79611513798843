//Action Types
export const FETCH_STATUS = 'FETCH_STATUS';
export const PUSH_STATUS = 'PUSH_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const REMOVE_STATUS = 'REMOVE_STATUS';

//Action Creator
export const fetchStatus = (data) => ({
   type: FETCH_STATUS,
   data: data
});

export const pushStatus = (data) => ({
    type: PUSH_STATUS,
    data: data
});

export const updateStatus = (data) => ({
    type: UPDATE_STATUS,
    data: data
});

export const removeStatus = (data) => ({
   type: REMOVE_STATUS,
   data: data
});