import React, { useRef, useState, useEffect } from "react";
import {connect} from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"
import axios from "axios";

const validationSchema = Yup.object().shape({
})

const CompanySetting = (props) => {
  const uploadFileEl = useRef()
  const [ csvFile, setCsvFile ] = useState({})
  const [ progress, setProgress ] = useState(0)
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState([])

  useEffect(() => {
    setLoading(true)
    client.authenticate()
    .then(()=>{
      return client.service('template-downloads').find({
        query: {
          userId: props.userId,
          $limit: 1
        }
      })
    })
    .then((res) => {
      setLoading(false)
      if(res.data.length > 0){
        setData(res.data[0])
        setCsvFile(res.data[0].csvFile)
      }else{
        setData({csvFile:{}})
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }, [props.userId])

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }

  const handleFileChange = async(e) => {
    setProgress(0)

    let singleFile = e.target.files[0]
    let fileType = (singleFile.type === "application/vnd.ms-excel" || singleFile.type === "text/csv")

    if(fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      setCsvFile(modified)

      return e.target.value = null
    }else{
      window.alert("Only Accept CSV File!")
      return false
    }
  }

  const handleDeleteCSV = (fileName) => {
    axios({
      method: 'post',
      url: `${client.io.io.uri}deleteCSVTemplateLocal`,
      data: {fileName},
      config: { headers: {'Content-Type': 'application/json' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  
  const csvUpload = async(file) => {

    const formData = new FormData();
    formData.append('NAME', 'Fred');
    formData.append('file', file)

    return await axios({
      method: 'post',
      url: `${client.io.io.uri}uploadCSVTemplateLocal`,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const onSubmit = async(value) => {
    setLoading(true)
    
    if(csvFile.name){
      let productDataUrl = await csvUpload(csvFile)

      // if removed, fileName will be moved as well
      if(data.csvFile.fileName){
        handleDeleteCSV(data.csvFile.fileName)
      }
      
      value.csvFile = {}
      value.csvFile.fileUrl = productDataUrl
      value.csvFile.fileName = csvFile.name
    }
    
    // if state nothing => remove all file
    // get from record data.csvFile
    if(!csvFile.name && !csvFile.fileName){
      if(data.csvFile.fileName){
        handleDeleteCSV(data.csvFile.fileName)
      }
      value.csvFile = {}
    }

    if(data._id){
      client.authenticate()
      .then(()=>{
        return client.service('template-downloads').patch(data._id, value)
      })
      .then((res) => {
        notificationOpen(true, 'success', "Template Created Successfully!")
        // setCsvFile({})
        setData(res)
        setLoading(false)
      })
      .catch((err)=>{
        setLoading(false)
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }else{
      client.authenticate()
      .then(()=>{
        return client.service('template-downloads').create(value)
      })
      .then((res) => {
        notificationOpen(true, 'success', "Template  Edited Successfully!")
        // setCsvFile({})
        setData(res)
        setLoading(false)
      })
      .catch((err)=>{
        setLoading(false)
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  const downloadTemplate = () => {
    window.location.href = `${client.io.io.uri}downloadCSVTemplate`
  }

  return (
    <>
      {/* Page content */}
      <Container fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="10">
            <div className="text-center mt-7">
              <h4 className="mb-0">Download this template and edit</h4>
              <p>(Upload the edited template to generate download link)</p>
              <Button
                className="mb-4"
                color="primary"
                type="button"
                onClick={downloadTemplate}
              >
                Download Template
              </Button>
            </div>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Template Setting</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {propsChild => {
                    const {
                      values,
                      // touched,
                      // errors,
                      // setFieldValue,
                      // handleChange,
                      handleSubmit,
                    } = propsChild;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      {/* <h6 className="heading-small text-muted mb-4">
                        Conversion Rate <strong>MYR</strong> to <strong>SGD</strong>
                      </h6> */}
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6 m-auto">
                            <div className="mb-2 text-center">
                              <small className="text-uppercase font-weight-bold">
                                Upload Data File
                              </small>
                            </div>
                            <FormGroup className="text-center">
                              {
                                !csvFile.name && !csvFile.fileName? (
                                  <div className="file-upload">
                                    <input 
                                      type="file" 
                                      ref={uploadFileEl}
                                      accept=".csv" 
                                      onChange={(e) => handleFileChange(e)} 
                                      hidden />                
                                      <div className="progessBar" style={{ width: progress }}>
                                    </div>
                                    <Button
                                      className="m-0 mb-1 w-100"
                                      color="default"
                                      onClick={() => uploadFileEl.current.click()}
                                    >
                                      <div className="btn-inner--icon">
                                        <span className="btn-inner--text">Upload File</span>
                                      </div>
                                    </Button>
                                  </div>
                                ): (
                                  <Row>
                                    <Col md="8 pr-0">
                                      <Input
                                        type="text"
                                        value={csvFile.name || csvFile.fileName}
                                        disabled
                                      />
                                    </Col>
                                    {
                                      values.source !== 'csv-prefix'? (
                                        <Col md="4 pl-0">
                                          <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => setCsvFile({})}
                                          >
                                            Remove
                                          </Button>
                                        </Col>
                                      ): null
                                    }
                                  </Row>
                                )
                              }
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <div className="text-center">
                        <h4 className="mb-0">Preview Link (Copy this link to your website)</h4>
                        {
                          data._id? (
                            <a href={`${window.location.protocol + "//" + window.location.host}/template/${data._id}`} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host}/template/{data._id}</a>
                          ): "-"
                        }
                      </div>
                      <hr className="my-4" />
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
};


const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  companyInfo: state.company.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);