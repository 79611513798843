/*eslint-disable*/
import React, { useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep"
import { Formik } from 'formik';
import * as Yup from 'yup';

// import ArtworkOption from "./ArtworkOption"
import ArtworkServices from "./ArtworkServices"
import AdditionalCalc from "./AdditionalCalc"
import AdditionalOptions from "./AdditionalOptions"
// import ProductDesc from "./ProductDesc"
import CSVForm from "./CSVForm"
import StickerForm from "./StickerForm"
import PosterForm from "./PosterForm"
import DocumentForm from "./DocumentForm"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  // Modal,
  Row,
  Col
} from "reactstrap";

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import client from "../../feathers.js"
import { storage } from "../../firebase";
import axios from "axios"
import env from "../../env.js"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Product Name is required'),
  source: Yup.string()
    .required('Source is required'),
})

const ProductModal = (props) => {
  const bgFileEl = useRef()
  const uploadFileEl = useRef()
  // const [ availability, setAvailability ] = useState('In Stock')
  const [ fileArray, setFileArray ] = useState([])
  const [ csvFile, setCsvFile ] = useState({})
  const [ timelineArray, setTimelineArray ] = useState([])
  const [ marginName, setMarginName ] = useState('')
  const [ nameFocused, setNameFocused ] = useState(false)
  // const [ codeFocused, setCodeFocused ] = useState(false)
  const [ amountFocused, setAmountFocused ] = useState(false)
  const [ progress, setProgress ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const [ productDetail, setProductDetail ] = useState('')
  const [ items, setItems ] = useState([])
  const [ additionOption, setAdditionOption ] = useState([])
  const [ manualCostBetween, setManualCostBetween ] = useState([])
  const [ manualCostGreater, setManualCostGreater ] = useState({minQ: 0, amount: 0})
  const [ manualCostDefault, setManualCostDefault ] = useState({minQ: 0, amount: 0})
  
  const handleFileChange = async(e) => {
    setProgress(0)
    
    let singleFile = e.target.files[0]
    
    let fileType = (singleFile.type === "application/vnd.ms-excel" || singleFile.type === "text/csv")

    if(fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      setCsvFile(modified)

      return e.target.value = null
    }else{
      window.alert("Only Accept CSV File!")
      return e.target.value = null
    }
  }

  const handleImgChange = (e) => {
    setProgress(0)

    let singleFile = e.target.files[0]
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
      let newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
        singleFile.lastModified + "-" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')

      let objectImg = URL.createObjectURL(e.target.files[0])
      let imgData = {
        name: singleFile.name,
        file: modified,
        preview: objectImg
      }
      setFileArray(fileArray.concat(imgData))

      return e.target.value = null
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  const removeFileInedx = (index) => {
    URL.revokeObjectURL(fileArray[index].preview)
    let result = cloneDeep(fileArray)
    result.splice(index, 1)
    setFileArray(result)
  }

  const removeFile = () => {
    fileArray.map((v) => {
      return URL.revokeObjectURL(v.preview)
    })
    setFileArray([])
  }

  const handleUpload = (path, file) => {
    setProgress(0)
    return new Promise(resolve => {
      let uploadTask = storage.ref(`${path}/${file.name}`).put(file)
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        error => {
          console.log(error);
        },
        ()=> {
          storage
            .ref(`${path}`)
            .child(file.name)
            .getDownloadURL()
            .then(url => {
              return resolve(url)
            })
        }
      )
    })
  }

  const imageProcess = (path, file) => {
    let result = handleUpload(path, file).then(v => v)
    return result
  }

  const csvUpload = async(file) => {

    const formData = new FormData();
    formData.append('NAME', 'Fred');
    formData.append('file', file)
    
    return await axios({
      method: 'post',
      url: `${client.io.io.uri}uploadCSVLocal`,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const onSubmit = async(value, { resetForm }) => {
    // setLoading(true)
    // value.availability = availability
    value.timelines = timelineArray
    // new function
    value.marginName = marginName

    value.items = items
    value.additionOption = additionOption
    value.manualCostBetween = manualCostBetween
    value.manualCostGreater = manualCostGreater
    value.manualCostDefault = manualCostDefault

    value.productDetail = productDetail
    
    if(fileArray.length > 0){
      let cloneFileArr = cloneDeep(fileArray)
      let processImg = await cloneFileArr.map(async(v) => {
        let imgUrl = await imageProcess('productImages', v.file)
        v.fileUrl = imgUrl
        v.fileName = v.file.name
        delete v.file
        delete v.preview
        delete v.name
        return v
      })
      
      const result = await Promise.all(processImg)
      value.productImg = result
    }

    if(csvFile.name){
      let productDataUrl = await csvUpload(csvFile)
      
      value.csvFile.fileUrl = productDataUrl
      value.csvFile.fileName = csvFile.name
    }

    if(props.user_Id){
      value.userId = props.user_Id
    }
    
    client.authenticate()
    .then(()=>{
      return client.service('products').create(value)
    })
    .then((res) => {
      res.marginName = props.marginsData.find(e => e._id === marginName)
      
      props.toggleModal()
      props.pushProducts(res)
      props.notificationOpen(true, 'success', "Product " + res.name + " Added Successfully!")
      removeFile()
      setCsvFile({})
      setLoading(false)
      resetForm()
    })
    .catch((err)=>{
      removeFile()
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }

  return (  
    <>
      {/* <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={() => {props.toggleModal(); removeFile()}}
      > */}
      <Drawer
        open={props.modalVisible}
        onClose={props.toggleModal}
        className='shadow-lg overflow-auto mac-scrollbar'
        direction='right'
        enableOverlay={false}
        size="81%"
      >
        <div className="px-4 py-2 d-flex align-items-center">
          <button
            aria-label="Close"
            className="close float-none"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true} style={{fontSize: '2rem', height: '2.25rem', width: '2.25rem', lineHeight: '2.8rem'}}>
              <i className="ni ni-fat-remove"/>
            </span>
          </button>
          <h4 className="modal-title">
            Add Product
          </h4>
        </div>
        <hr className="mt-0 mb-2"/>
        <Formik
          initialValues={{
            name: '',
            source: 'csv',
            sourceFilterBy: '',
            supplier: 'other',
            csvFile: {
              fileName: '',
              fileUrl: '',  
            },
            roundedOptions: [],
            punchHoleOptions: [],
            hotstampOptions: [],
            splitOptions: [],
            additionalData: [],
            code: '',
            category: 'Other',
            netAmount: 0,
            foldingBase: 0,
            foldingPercent: 0,
            calcSqFtIn: "mm",
            doubleSidePercent: 1,
            mergingFee: 0,
            merginVariable: 0,
            status: true,
            mostPopular: false,
            artworkServices: [],
            widthRange: {
              min: 0,
              max: 0,
            },
            heightRange: {
              min: 0,
              max: 0,
            },
            bleeding: {
              width: 0,
              height: 0,
            },
            // artworkServiceAmount: 0,
            // artworkoption: ['0', '1'],
            // artworkLink: {
            //   name: '',
            //   link: ''
            // },
            packageSize: 'small',
            keywords: [],
            // localShippingAmount: 0,
            // sgDelivery: { name: 'small', minAmt: 50, amount: 6 },
            availability: '',
            description: '',
            shippingInfo: '',
            manualPercent: 0
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {propsChild => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            } = propsChild;
            return (
              <Form role="form" onSubmit={handleSubmit}>
                <div className="modal-body pt-0">
                  {/* <div className="mb-2">
                    <small className=" font-weight-bold">
                      Product Images
                    </small>
                  </div> */}
                  {/* <Row className="justify-content-center mb-4">
                    {fileArray.length > 0? 
                      fileArray.map((v, i) => {
                        return (
                          <Col md="4" lg="4" key={i}>
                            <FormGroup className="text-center">
                              <Card className="mb-2 shadow-sm">
                                <CardBody className="p-0">
                                  <div className="file-component">
                                    <div className="file-overlay">
                                      <div className="file-buttons">
                                        <Button
                                          className="border-0"
                                          color="danger"
                                          onClick={() => removeFileInedx(i)}
                                          >
                                          <div className="btn-inner--icon">
                                            <span className="btn-inner--text">Remove</span>
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                    <img className="w-100" src={v.preview} alt="entreship sol" />
                                  </div>
                                </CardBody>
                              </Card>
                              <span style={{ overflowWrap: 'break-word' }}>{v.name}</span>
                            </FormGroup>
                          </Col>
                        )
                      }): null}
                      {fileArray.length < 10? (
                      <>
                        <div className="file-upload">
                          <input 
                            type="file" 
                            ref={bgFileEl} 
                            onChange={(e) => handleImgChange(e)} 
                            hidden />                
                            <div className="progessBar" style={{ width: progress }}>
                          </div>
                          <Button
                            className="m-0 mb-1 w-100"
                            color="secondary"
                            onClick={() => bgFileEl.current.click()}
                          >
                            <div className="btn-inner--icon">
                              <span className="btn-inner--text">Upload Images</span>
                            </div>
                          </Button>
                        </div>
                      </>
                    ): null}
                  </Row> */}
                  <Card className="shadow mb-4">
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <small className="font-weight-bold">
                              Source Data
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="secondary" className="w-100 text-capitalize">
                                {values.source?values.source.replace(/([A-Z])/g, ' $1').trim(): '-'}
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'csv'} 
                                    onClick={() => {
                                      setFieldValue('source', 'csv')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    CSV
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'sticker'} 
                                    onClick={() => {
                                      setFieldValue('source', 'sticker')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    Sticker
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'poster'} 
                                    onClick={() => {
                                      setFieldValue('source', 'poster')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    Poster
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'loose-sheet'} 
                                    onClick={() => {
                                      setFieldValue('source', 'loose-sheet')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    Loose Sheet
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'certificate'} 
                                    onClick={() => {
                                      setFieldValue('source', 'certificate')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    Certificate
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'document'} 
                                    onClick={() => {
                                      setFieldValue('source', 'document')
                                      setItems([])
                                      setAdditionOption([])
                                    }}
                                  >
                                    Document
                                  </DropdownItem>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col>
                        {
                          props.role === 'superadmin'? (
                            <Col md="6">
                              <div className="mb-2">
                                <small className=" font-weight-bold">
                                  Supplier
                                </small>
                              </div>
                              <FormGroup className="text-center">
                                <UncontrolledDropdown nav inNavbar className="w-100">
                                  <DropdownToggle caret color="secondary" className="w-100 text-capitalize">
                                    {values.supplier?values.supplier: '-'}
                                  </DropdownToggle>
                                  <DropdownMenu className="w-100">
                                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                                      {
                                        env.suppliers.map((x, i) => {
                                          return (
                                            <DropdownItem 
                                              key={i}
                                              className="text-uppercase" 
                                              value={x} 
                                              onClick={() => {
                                                setFieldValue('supplier', x)
                                              }}
                                            >
                                              {x}
                                            </DropdownItem>
                                          )
                                        })
                                      }
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </FormGroup>
                            </Col>
                          ): null
                        }
                        {/* <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Category
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="secondary" className="w-100 ">
                                {values.category}
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  {
                                    categories.map((x, i) => {
                                      return(
                                        <DropdownItem 
                                          key={i}
                                          className="text-capitalize" 
                                          value={x.name} 
                                          onClick={() => setFieldValue('category', x.name)}
                                        >
                                          {x.name}
                                        </DropdownItem>
                                      )
                                    })
                                  }
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      
                      <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <small className="font-weight-bold">
                              Product Name
                            </small>
                          </div>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: nameFocused
                            }, {
                              "has-danger": errors.name && touched.name
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Product Name"
                                type="text"
                                id="name"
                                value={values.name}
                                onChange={handleChange}
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => {
                                  setNameFocused(false);
                                  handleBlur(e)
                                }}
                              />
                            </InputGroup>
                            {errors.name && touched.name && (
                              <div className="input-feedback">{errors.name}</div>
                            )}
                          </FormGroup>
                        </Col>
                        {/* <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Product Code
                            </small>
                          </div>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: codeFocused
                            }, {
                              "has-danger": errors.code && touched.code
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-qrcode"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Product Code"
                                type="text"
                                id="code"
                                rows="3"
                                value={values.code}
                                onChange={handleChange}
                                onFocus={e => setCodeFocused(true)}
                                onBlur={e => {
                                  setCodeFocused(false);
                                  handleBlur(e)
                                }}
                              />
                            </InputGroup>
                            {errors.code && touched.code && (
                              <div className="input-feedback">{errors.code}</div>
                            )}
                          </FormGroup>
                        </Col> */}
                      </Row>

                      {
                        values.source === 'csv' || values.source === 'csv-prefix'? (
                          <>
                            <CSVForm 
                              csvFile={csvFile}
                              uploadFileEl={uploadFileEl}
                              handleFileChange={handleFileChange} 
                              progress={progress}
                              setCsvFile={setCsvFile}
                              values={values}
                              currency={props.currency}
                            />
                          </>
                        ): null
                      }

                      {
                        values.source === 'sticker' || values.source === 'loose-sheet' ?
                        (
                          <StickerForm 
                            amountFocused={amountFocused}
                            setAmountFocused={setAmountFocused}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            items={items}
                            setItems={setItems}
                            manualCostBetween={manualCostBetween} 
                            setManualCostBetween={setManualCostBetween}
                            manualCostGreater={manualCostGreater} 
                            setManualCostGreater={setManualCostGreater}
                            manualCostDefault={manualCostDefault}
                            setManualCostDefault={setManualCostDefault}
                            source={values.source}
                            currency={props.currency}
                          />
                        ): null
                      }

                      {
                        values.source === 'poster' ?
                        (
                          <PosterForm 
                            amountFocused={amountFocused}
                            setAmountFocused={setAmountFocused}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            items={items}
                            setItems={setItems}
                            currency={props.currency}
                          />
                        ): null
                      }
                      
                      {
                        values.source === 'certificate' || values.source === 'document' ?
                        (
                          <DocumentForm 
                            amountFocused={amountFocused}
                            setAmountFocused={setAmountFocused}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            items={items}
                            setItems={setItems}
                            manualCostBetween={manualCostBetween} 
                            setManualCostBetween={setManualCostBetween}
                            manualCostGreater={manualCostGreater} 
                            setManualCostGreater={setManualCostGreater}
                            manualCostDefault={manualCostDefault}
                            setManualCostDefault={setManualCostDefault}
                            source={values.source}
                            currency={props.currency}
                          />
                        ): null
                      }

                      {/* For all calculator */}
                      {
                        values.source === 'sticker'
                        || values.source === 'poster' 
                        || values.source === 'loose-sheet' 
                        || values.source === 'certificate'
                        || values.source === 'document'?
                        (
                          <AdditionalOptions
                            additionOption={additionOption}
                            setAdditionOption={setAdditionOption}
                            currency={props.currency}
                          />
                        ): null
                      }
                      
                      {/* <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Most Popular
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="secondary" className="w-100">
                                {values.mostPopular?"Active":"Disabled"}
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={true} 
                                    onClick={() => setFieldValue('mostPopular', true)}
                                  >
                                    Active
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={false} 
                                    onClick={() => setFieldValue('mostPopular', false)}
                                  >
                                    Disabled
                                  </DropdownItem>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Product Status
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="secondary" className="w-100">
                                {values.status?"Active":"Disabled"}
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={true} 
                                    onClick={() => setFieldValue('status', true)}
                                  >
                                    Active
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={false} 
                                    onClick={() => setFieldValue('status', false)}
                                  >
                                    Disabled
                                  </DropdownItem>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col>
                      </Row> */}
                      {/* <Row>
                        <Col md="6">
                          <div className="mb-2">
                            <small className=" font-weight-bold">
                              Package Size
                            </small>
                          </div>
                          <FormGroup className="text-center">
                            <UncontrolledDropdown nav inNavbar className="w-100">
                              <DropdownToggle caret color="secondary" className="w-100">
                                {values.packageSize?values.packageSize.toUpperCase():'-'}
                              </DropdownToggle>
                              <DropdownMenu className="w-100">
                                <div className="editInputs-colors-scrollbar mac-scrollbar">
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'small'} 
                                    onClick={() => setFieldValue('packageSize', 'small')}
                                  >
                                    Small
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'medium'} 
                                    onClick={() => setFieldValue('packageSize', 'medium')}
                                  >
                                    Medium
                                  </DropdownItem>
                                  <DropdownItem 
                                    className="text-capitalize" 
                                    value={'large'} 
                                    onClick={() => setFieldValue('packageSize', 'large')}
                                  >
                                    Large
                                  </DropdownItem>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <FormGroup>
                        <div className="mb-2">
                          <small className=" font-weight-bold">
                            Description
                          </small>
                        </div>
                        <Input
                          className="form-control-alternative"
                          placeholder="Description"
                          id="description"
                          value={values.description}
                          onChange={handleChange}
                          autoComplete="off"
                          type="textarea"
                          rows="3"
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <div className="mb-2">
                          <small className=" font-weight-bold">
                            Shipping Info
                          </small>
                        </div>
                        <Input
                          className="form-control-alternative"
                          placeholder="Shipping Info"
                          id="shippingInfo"
                          value={values.shippingInfo}
                          onChange={handleChange}
                          autoComplete="off"
                          type="textarea"
                          rows="1"
                        />
                      </FormGroup> */}
                      {/* <FormGroup>
                        <div className="mb-2 d-flex justify-content-between">
                          <small className=" font-weight-bold">
                            SEO Keywords
                          </small>
                          <Button color="primary" type="button" size="sm" onClick={() => {
                            let keyw = values.keywords
                            keyw.push('')
                            setFieldValue('keywords', keyw)
                          }}>
                            Add Keyword
                          </Button>
                        </div>
                        <FieldArray
                          name="keywords"
                          render={arrayHelpers => (
                            <Row>
                              {
                                values.keywords.length > 0? (
                                  values.keywords.map((keyword, index) => (
                                    <Col md="3" key={index}>
                                      <Row>
                                        <Col md="8" className="p-0">
                                          <Field className="form-control form-control-sm" name={`keywords.${index}`} />
                                        </Col>
                                        <Col md="4" className="p-0">
                                          <Button
                                            type="button"
                                            color="primary"
                                            size="sm"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                          >
                                            -
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))
                                ): null
                              }
                            </Row>
                          )}
                        />
                      </FormGroup> */}
                    </CardBody>
                  </Card>

                  {
                    props.role !== 'superadmin'? (
                      <>
                        <Card className="shadow mb-4">
                          <CardBody>
                            <small className=" font-weight-bold">
                              Profit Margin
                            </small>
                            <Row>
                              <Col md="12" className="text-center">
                                <Input 
                                  className="mr-2 w-auto d-inline-block"
                                  value={props.marginsData.find(e => e._id === marginName)? props.marginsData.find(e => e._id === marginName).marginName: ""} 
                                  disabled
                                />
                                <UncontrolledDropdown nav inNavbar>
                                  <DropdownToggle caret color="secondary" className="">
                                    Apply Margin
                                  </DropdownToggle>
                                  <DropdownMenu className="w-100">
                                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                                      <DropdownItem 
                                        className="bg-danger text-white text-capitalize" 
                                        value={''} 
                                        onClick={() => {
                                          setMarginName('')
                                        }}
                                      >
                                        Unlink Margin
                                      </DropdownItem>
                                      {
                                        props.marginsData.map((x, i) => {
                                          return (
                                            <DropdownItem
                                              key={i} 
                                              className="text-capitalize" 
                                              value={x.marginName} 
                                              onClick={() => {
                                                setMarginName(x._id)
                                              }}
                                            >
                                              {x.marginName}
                                            </DropdownItem>
                                          )
                                        })
                                      }
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <br/>
                                <br/>
                                <p>*To edit margin, Please go to margin page to edit and apply again</p>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ): null
                  }

                  {/* <ArtworkOption 
                    values={values}
                    handleChange={handleChange}
                  /> */}

                  {
                    props.role !== 'superadmin'? (
                      <>
                        <ArtworkServices 
                          values={values}
                          currency={props.currency}
                        />
                        <AdditionalCalc 
                          timelineArray={timelineArray}
                          setTimelineArray={setTimelineArray}
                          marginsData={props.marginsData}
                          currency={props.currency}
                        />
                      </>
                    ): null
                  }

                  {/* <div className="mt-4">
                    <ProductDesc 
                      productDetail={productDetail}
                      setProductDetail={setProductDetail}
                    />
                  </div> */}

                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {props.toggleModal(); removeFile()}}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading? (
                      <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ):null}
                    Confirm
                  </Button>
                </div>
              </Form>
              );
            }}
          </Formik>
        </Drawer>
      {/* </Modal> */}
    </>
  );
}
 
export default ProductModal;