import React from "react";
import cloneDeep from "lodash/cloneDeep"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Table,
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from "reactstrap";

const PosterForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  setFieldValue,
  currency
}) => {

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', basePrice: 0, amount: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)

    if(child === 'amountBetween' && name === 'maxQ'){
      if(cloneItems[index][child][index1+1]){
        cloneItems[index][child][index1+1]['minQ'] = (parseFloat(val) + 0.0001).toFixed(4) 
      }else{
        cloneItems[index]['amountGreater'].minQ = (parseFloat(val) + 0.0001).toFixed(4)
      }
      cloneItems[index][child][index1][name] = val
    }
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  const addItem = () => {
      setItems(items.concat({
        name: '',
        basePrice: 0,
        width: {
          min: 0,
          max: 0,
          amt: 0,
        },
        height: {
          min: 0,
          max: 0,
          amt: 0,
        },
        amountDefault: {minQ: 0, amount: 0},
        amountGreater: {minQ: 0, amount: 0},
        amountBetween: [],
        variations: []
      }))
  }

  const addBetweenLine = (x, i) => {
    itemOnChange(x.amountBetween.concat({
      minQ: x.amountBetween.length > 0? (parseFloat(x.amountBetween[x.amountBetween.length-1]['maxQ']) + 0.0001).toFixed(4): (parseFloat(x.amountDefault.minQ) + 0.0001).toFixed(4),
      maxQ: 0,
      amount: 0
    }), 'amountBetween', i)
  }

  const onChangeAmoutDefault = (e, index, x) => {
    let cloneItems = cloneDeep(items)
    let val = e.target.value? e.target.value: 0
    cloneItems[index]['amountDefault'] = {minQ: val, amount: x.amountDefault.amount}
    
    if(x.amountBetween.length > 0){
      cloneItems[index]['amountBetween'][0].minQ = (parseFloat(val) + 0.0001).toFixed(4)
    }else{
      cloneItems[index]['amountGreater'].minQ = (parseFloat(val) + 0.0001).toFixed(4)
    }

    setItems(cloneItems)
    
  }

  const duplicateItem = (x) => {
    let cloneItems = cloneDeep(items)

    if(x.amountBetween){
      if(x.amountBetween.length > 0){
        x.amountBetween.map(x => {
          delete x._id
          return x
        })
      }
    }

    if(x.variations){
      if(x.variations.length > 0){
        x.variations.map(x => {
          delete x._id
          return x
        })
      }
    }

    cloneItems = cloneItems.concat(x)
    
    setItems(cloneItems)
  }

  return (  
    <>
      <Row>
        <Col md="3">
          <div className="mb-2">
            <small className="font-weight-bold">
              Base Price (<span className="text-uppercase">{currency}</span>)
            </small>
          </div>
          <FormGroup
            className={classnames("mb-3", {
              focused: amountFocused
            }, {
              "has-danger": errors.netAmount && touched.netAmount
            })}
          >
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-money-coins" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Product Net Amount"
                type="number"
                id="netAmount"
                value={Number(values.netAmount).toString()}
                onChange={handleChange}
                onFocus={e => setAmountFocused(true)}
                onBlur={e => {
                  setAmountFocused(false);
                  handleBlur(e)
                }}
                onWheel={ event => event.currentTarget.blur() }
              />
            </InputGroup>
            {errors.netAmount && touched.netAmount && (
              <div className="input-feedback">{errors.netAmount}</div>
            )}
          </FormGroup>
        </Col>
        <Col md="3">
          <div className="mb-2">
            <small className="font-weight-bold">
              Measure in
            </small>
          </div>
          <FormGroup className="text-center">
            <UncontrolledDropdown nav inNavbar className="w-100">
              <DropdownToggle caret color="secondary" className="w-100 text-uppercase">
                {values.calcSqFtIn?values.calcSqFtIn: '-'}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                <div className="editInputs-colors-scrollbar mac-scrollbar">
                  <DropdownItem 
                    className="text-uppercase" 
                    value={'mm'} 
                    onClick={() => {
                      setFieldValue('calcSqFtIn', 'mm')
                    }}
                  >
                    MM
                  </DropdownItem>
                  <DropdownItem 
                    className="text-uppercase" 
                    value={'ft'} 
                    onClick={() => {
                      setFieldValue('calcSqFtIn', 'inch')
                    }}
                  >
                    INCH
                  </DropdownItem>
                  <DropdownItem 
                    className="text-uppercase" 
                    value={'ft'} 
                    onClick={() => {
                      setFieldValue('calcSqFtIn', 'ft')
                    }}
                  >
                    FT
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>
        </Col>
      </Row>
      
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className="text-capitalize font-weight-bold">
                Poster Materials
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => addItem()}
                >
                  <div className="btn-inner--text">
                    <span>Add Item</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="">

                {
                  items.length > 0? 
                    items.map((x, i) => {
                      return (
                        <Card key={i} className="shadow mb-4">
                          <CardBody>
                            {/* <th scope="row">
                              {i+1}.
                            </th> */}
                            <Row className="align-items-center">
                              <Col md="5">
                                <small className="font-weight-bold">Name of Material</small>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Name"
                                  type="text"
                                  bsSize="sm"
                                  value={x.name}
                                  onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                                />
                              </Col>
                              <Col md="3">
                                <small className="font-weight-bold">
                                  Base Price (<span className="text-uppercase">{currency}</span>)
                                </small>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Base Price"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.basePrice).toString()}
                                  onChange={(e) => itemOnChange(Number(e.target.value), 'basePrice', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </Col>
                              <Col md="4">
                                <div className="d-flex justify-content-end align-items-center">
                                  <Button
                                    color="warning"
                                    type="button"
                                    size="sm"
                                    onClick={() => duplicateItem(x)}
                                  >
                                    <div className="btn-inner--text">
                                      <span>Duplicate</span>
                                    </div>
                                  </Button>
                                  <span>
                                    <i 
                                    className="ni ni-fat-remove"
                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                    onClick={() => removeSticker(i)}
                                    />
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <hr className="mb-2" />
                            <div>
                              <Row className="mb-2">
                                <Col md="6">
                                  <Row>
                                    <Col md="4">
                                      <small className="font-weight-bold">Min Width(mm)</small>
                                      <Input
                                        className="form-control"
                                        placeholder="Min Width"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.width.min).toString()}
                                        onChange={(e) => itemOnChange({min: e.target.value, max: x.width.max, amt: x.width.amt}, 'width', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    <Col md="4">
                                      <small className="font-weight-bold">Max Width(mm)</small>
                                      <Input
                                        className="form-control"
                                        placeholder="Max Width"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.width.max).toString()}
                                        onChange={(e) => itemOnChange({max: e.target.value, min: x.width.min, amt: x.width.amt}, 'width', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    <Col md="4">
                                      <small className="font-weight-bold">
                                        Min Amount(<span className="text-uppercase">{currency}</span>)
                                      </small>
                                      <Input
                                        className="form-control"
                                        placeholder="Base Price"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.width.amt).toString()}
                                        onChange={(e) => itemOnChange({amt: e.target.value, min: x.width.min, max: x.width.max}, 'width', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="6" className="border-left">
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <small className="font-weight-bold">Min Height(mm)</small>
                                      <Input
                                        className="form-control"
                                        placeholder="Min Height"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.height.min).toString()}
                                        onChange={(e) => itemOnChange({min: e.target.value, max: x.height.max, amt: x.height.amt}, 'height', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    <Col md="4">
                                      <small className="font-weight-bold">Max Height(mm)</small>
                                      <Input
                                        className="form-control"
                                        placeholder="Max Height"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.height.max).toString()}
                                        onChange={(e) => itemOnChange({max: e.target.value, min: x.height.min, amt: x.height.amt}, 'height', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    {/* <Col>
                                      <small className="font-weight-bold">Min Amount</small>
                                      <Input
                                        className="form-control"
                                        placeholder="Base Price"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.height.amt).toString()}
                                        onChange={(e) => itemOnChange({amt: e.target.value, min: x.height.min, max: x.height.max}, 'height', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col> */}
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                            <hr className="my-2" />
                            <div>
                              <Row className="mb-2">
                                <Col md="12" lg="6">
                                  <small className="font-weight-bold">
                                    Printing Cost
                                  </small>
                                  {/* <Row className="mb-2 justify-content-center">
                                    <Col md="5">
                                      <small className="font-weight-bold">
                                        <span className="text-uppercase">{currency}</span>/Sq Feet
                                      </small>
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Amount"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x.amountDefault.amount).toString()}
                                        onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amount: Number(e.target.value)}, 'amountDefault', i)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                  </Row> */}
                                  <Table style={{tableLayout: 'fixed'}} className="align-items-center table-flush" size="sm">
                                    <thead>
                                      <tr>
                                        <th style={{paddingLeft: 0, paddingRight: '1.5rem'}}>Min Sq Feet</th>
                                        <th style={{paddingLeft: '1rem', paddingRight: '1rem'}}>Max Sq Feet</th>
                                        <th><span className="text-uppercase">{currency}</span>/Sq Feet</th>
                                        <th>
                                          <Button
                                            color="secondary"
                                            type="button"
                                            size="sm"
                                            onClick={() => addBetweenLine(x, i)}
                                          >
                                            <div className="btn-inner--text">
                                              <span>Add Line</span>
                                            </div>
                                          </Button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="px-1">
                                          <Input
                                            className="form-control"
                                            placeholder="Min Q"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountDefault.minQ).toString()}
                                            onChange={(e) => onChangeAmoutDefault(e, i, x)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td>{"<"}</td>
                                        <td className="px-1">
                                          <Input
                                            className="form-control-alternative"
                                            placeholder="Amount"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountDefault.amount).toString()}
                                            onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amount: Number(e.target.value)}, 'amountDefault', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                        {
                                          x.amountBetween.length > 0? 
                                            x.amountBetween.map((x1, i1) => {
                                              return (
                                                <tr key={i1} className="">
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Min Q"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.minQ).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'minQ', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                      disabled
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Max Q"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.maxQ).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'maxQ', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control-alternative"
                                                      placeholder="Amount"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.amount).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'amount', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td>
                                                    <i 
                                                    className="ni ni-fat-remove"
                                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                                    onClick={() => removeChild('amountBetween', i, i1)}
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            }): null
                                        }
                                      <tr className="">
                                        <td className="px-1">
                                          <Input
                                            className="form-control"
                                            placeholder="Min Q"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountGreater.minQ).toString()}
                                            onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountGreater.amount}, 'amountGreater', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                            disabled
                                          />
                                        </td>
                                        <td>{">"}</td>
                                        <td className="px-1">
                                          <Input
                                            className="form-control-alternative"
                                            placeholder="Amount"
                                            type="number"
                                            bsSize="sm"
                                            value={Number(x.amountGreater.amount).toString()}
                                            onChange={(e) => itemOnChange({minQ: x.amountGreater.minQ, amount: Number(e.target.value)}, 'amountGreater', i)}
                                            onWheel={ event => event.currentTarget.blur() }
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col md="12" lg="6">
                                  <small className="font-weight-bold">
                                    Finishing Cost
                                  </small>
                                  <Card>
                                    <CardBody className="p-0">
                                      <Table style={{tableLayout: 'fixed'}} className="align-items-center table-flush" size="sm">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th className="p-0">Base Price(<span className="text-uppercase">{currency}</span>)</th>
                                            <th><span className="text-uppercase">{currency}</span>/Sq Feet</th>
                                            <th>
                                              <Button
                                                color="secondary"
                                                type="button"
                                                size="sm"
                                                onClick={() => pushStickerVar(i)}
                                              >
                                                <div className="btn-inner--text">
                                                  <span>Add Line</span>
                                                </div>
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            x.variations.map((x1, i1) => {
                                              return (
                                                <tr key={i1} className="">
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Name"
                                                      type="text"
                                                      bsSize="sm"
                                                      value={x1.name}
                                                      onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control"
                                                      placeholder="Base Price"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.basePrice).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'basePrice', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td className="px-1">
                                                    <Input
                                                      className="form-control-alternative"
                                                      placeholder="Per Sq Feet Amount"
                                                      type="number"
                                                      bsSize="sm"
                                                      value={Number(x1.amount).toString()}
                                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                                      onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                  </td>
                                                  <td>
                                                    <i 
                                                    className="ni ni-fat-remove"
                                                    style={{fontSize: '1rem', cursor:'pointer'}}
                                                    onClick={() => removeChild('variations', i, i1)}
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          }
                                          </tbody>
                                        </Table>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      )
                    }): null
                }
                
          </div>
        </CardBody>
      </Card>
    </>
  );
}
 
export default PosterForm;