import React from "react";
import { Field, FieldArray } from 'formik';
// reactstrap components
import {
  Button,
  FormGroup,
  Table,
  Card,
  CardBody,
} from "reactstrap";

const ArtworkServices = ({values, currency}) => {
  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <div className="mb-2">
            <small className="font-weight-bold">
              Artwork Services (This price is excluded from profit margin calculation)
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="artworkServices"
              render={arrayHelpers => (
                <>
                <div className="table-responsive mac-scrollbar-white">
                  <Table className="align-items-center table-flush" size="sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Cost(<span className="text-uppercase">{currency}</span>)</th>
                        <th></th>
                        <th></th>
                        <th className="pr-2">
                          <div className="d-flex justify-content-end">
                            <Button 
                              color="secondary"
                              type="button"
                              size="sm"
                              onClick={() => arrayHelpers.push({ 
                                name: '', 
                                cost: 0,
                              })}
                            >
                              <div className="btn-inner--text">
                                <span>Add Artwork</span>
                              </div>
                            </Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.artworkServices.map((artwork, index) => (
                        <tr key={index} className="px-1">
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              placeholder="Name"
                              type="text"
                              name={`artworkServices[${index}].name`} />
                          </td>
                          <td className="px-1">
                            <Field 
                              className="form-control form-control-sm"
                              style={{width: '50%'}}
                              placeholder="Cost Price"
                              type="number"
                              onWheel={ event => event.currentTarget.blur() }
                              name={`artworkServices.${index}.cost`} />
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <i 
                                className="ni ni-fat-remove"
                                style={{fontSize: '1rem', cursor:'pointer'}}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                </>
              )}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
}
 
export default ArtworkServices;