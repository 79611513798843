import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchUsers, pushUsers, updateUsers } from '../redux/actions/usersActions';
// import moment from 'moment';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";

import UserModal from "../components/Users/UserModal.js"
import UserModalEdit from "../components/Users/UserModalEdit.js"
import PasswordModal from "../components/Users/PasswordModal.js"

import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"

const PAGESIZE = 10;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Users = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalVisiblePassword, setMmodalVisiblePassword ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  
  useEffect(() => {
    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1)
  }, [props.userId, props.fetchUsers])

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalPassword = () => {
    setMmodalVisiblePassword(!modalVisiblePassword)
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchUsers, notificationOpen, skip, currentPage) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('users').find({
          query: {
            role: 'admin',
            $skip: skip,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage))
        fetchUsers(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchUsers, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchUsers, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchUsers, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header pb-6 pt-4 pt-md-7">
        <span className="mask" style={{backgroundColor: '#fff'}} />
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Merchants List</h3>
                  </Media>
                  <Media body className="text-right">
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--text">Create</span>
                    </Button>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush" style={{minHeight: 200}}>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Role</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Total Products</th>
                      <th scope="col">Avai Algorithm</th>
                      <th scope="col">Avai Products</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props.usersData.length > 0? (
                      props.usersData.map((v, i) => {
                        return (
                          <tr key={i}>
                            {/* <th scope="row">
                              <Media className="align-items-center">
                                <a
                                  href="/#"
                                  className="avatar rounded-circle mr-3"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {v.profileImage.fileUrl? (
                                    <div
                                      className="img rounded-circle overflow-hidden bg-white d-inline-block"
                                      style={{ height: '100%', verticalAlign: 'middle', borderStyle: 'none' }}
                                    >
                                      <img
                                        alt="entreship sol"
                                        className="w-100"
                                        src={v.profileImage.fileUrl}
                                      />
                                    </div>
                                  ):(
                                    <i className="ni ni-image"></i>
                                  )}
                                </a>
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {v.username}
                                  </span>
                                </Media>
                              </Media>
                            </th> */}
                            <td>{v.email}</td>
                            <td>{
                              v.role?
                                (v.role) === 'admin'? "Merchant" : v.role
                                :v.role
                              }</td>
                            <td>{v.currency?(v.currency).toUpperCase():v.currency}</td>
                            <td>{v.totalProducts}</td>
                            <td>{v.numbOfMargins}</td>
                            <td>{v.numbOfActiveProduct}</td>
                            {/* <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.status? "bg-success": "bg-danger"} />
                                {v.status?"Active":"Disabled"}
                              </Badge>
                            </td> */}
                            {/* <td>{moment(v.createdAt).format("MMM Do YYYY")}</td> */}
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalPassword()}}
                                  >
                                    Edit Password
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => props.history.push('/superadmin/userProducts/'+v._id)}
                                  >
                                    Edit Products
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                          )
                        })
                      ): (
                        <tr>
                          <td>
                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                          </td>
                        </tr>
                        )
                      }
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <UserModal 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
        notificationOpen={notificationOpen} 
        pushUsers={props.pushUsers}
      />
      <UserModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen} 
        updateUsers={props.updateUsers}
      />
      <PasswordModal 
        dataEdit={dataEdit}
        modalVisible={modalVisiblePassword} 
        toggleModal={toggleModalPassword}
        notificationOpen={notificationOpen} 
        updateUsers={props.updateUsers}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  usersData: state.users.data,
});

const mapDispatchToProps = {
  fetchUsers: fetchUsers,
  pushUsers: pushUsers,
  updateUsers: updateUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);