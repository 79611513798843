// import Index from "./views/Index.js";
import Users from "./views/Users.js";
import Products from "./views/Products.js";
// import Invoices from "./views/Invoices.js";
// import Transactions from "./views/Transactions.js";
import Profile from "./views/Profile.js";
// import Status from "./views/Status";
import Margins from "./views/Margins";
// import ShippingSetting from "./views/ShippingSetting.js";
import CompanySetting from "./views/CompanySetting.js";
import TemplateSetting from "./views/TemplateSetting.js";
// import Register from "./views/Register.js";
import Login from "./views/Login.js";

// import Maps from "views/examples/Maps.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  //   show: true,
  // },
  {
    path: "/index",
    name: "Products",
    icon: "ni ni-box-2 text-white",
    component: Products,
    layout: "/superadmin",
    show: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-default",
    component: Users,
    layout: "/superadmin",
    show: true,
  },
  {
    path: "/margin",
    name: "Margin Setting",
    icon: "ni ni-delivery-fast text-success",
    component: Margins,
    layout: "/superadmin",
    show: true,
  },
  {
    path: "/setting",
    name: "Setting",
    icon: "ni ni-settings text-info",
    component: CompanySetting,
    layout: "/superadmin",
    show: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/superadmin",
    show: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    show: false,
  },
  {
    path: "/auth/login",
    name: "Logout",
    icon: "ni ni-user-run text-danger",
    layout: "/superadmin",
    show: true,
  },
  // {
  //   path: "/invoices",
  //   name: "Invoices",
  //   icon: "ni ni-single-copy-04 text-primary",
  //   component: Invoices,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/transactions",
  //   name: "Transactions",
  //   icon: "ni ni-chart-bar-32 text-info",
  //   component: Transactions,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/status",
  //   name: "Status",
  //   icon: "ni ni-notification-70 text-orange",
  //   component: Status,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/shipping-setting",
  //   name: "Shipping Setting",
  //   icon: "ni ni-delivery-fast text-pink",
  //   component: ShippingSetting,
  //   layout: "/admin",
  //   show: true,
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
  {
    path: "/index",
    name: "Products",
    icon: "ni ni-box-2 text-white",
    component: Products,
    layout: "/admin",
    show: true,
  },
  {
    path: "/margin",
    name: "Margin Setting",
    icon: "ni ni-delivery-fast text-success",
    component: Margins,
    layout: "/admin",
    show: true,
  },
  {
    path: "/template-setting",
    name: "Template Link",
    icon: "ni ni-map-big text-orange",
    component: TemplateSetting,
    layout: "/admin",
    show: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/admin",
    show: true,
  },
  {
    path: "/auth/login",
    name: "Logout",
    icon: "ni ni-user-run text-danger",
    layout: "/admin",
    show: true,
  },
];
export default routes;
