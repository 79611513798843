import React from "react";

// reactstrap components
import { Modal, Button } from "reactstrap";

const NotificationType = (props) => {
  if(props.color === "primary"){
    return (
      <Modal
        className="modal-dialog-centered modal-primary"
        contentClassName="bg-gradient-primary"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          {props.title?props.title: "Primary"}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.handleOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bulb-61 ni-3x" />
          <h4 className="heading mt-4">Primary Message!</h4>
          <p>
            {props.message}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button 
          className="btn-white" 
          color="default" 
          type="button"
          onClick={() => props.handleOpen(false)}
        >
          Ok, Got it
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => props.handleOpen(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
    )
  }else if(props.color === "secondary"){
    return (
      <Modal
        className="modal-dialog-centered modal-secondary"
        contentClassName="bg-gradient-secondary"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Secondary"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Secondary Message!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }else if(props.color === "success"){
    return (
      <Modal
        className="modal-dialog-centered modal-success"
        contentClassName="bg-gradient-success"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Success"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Updated Successfully!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }else if(props.color === "info"){
    return (
      <Modal
        className="modal-dialog-centered modal-info"
        contentClassName="bg-gradient-info"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Info"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Info Message!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }else if(props.color === "warning"){
    return (
      <Modal
        className="modal-dialog-centered modal-warning"
        contentClassName="bg-gradient-warning"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Warning"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Warning Message!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }else if(props.color === "danger"){
    return (
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Danger"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Danger Message!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }else{
    return (
      <Modal
        className="modal-dialog-centered modal-default"
        contentClassName="bg-gradient-default"
        isOpen={props.isOpen}
        toggle={() => props.handleOpen(!props.isOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            {props.title?props.title: "Default"}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bulb-61 ni-3x" />
            <h4 className="heading mt-4">Default Message!</h4>
            <p>
              {props.message}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button 
            className="btn-white" 
            color="default" 
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Ok, Got it
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => props.handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }
}

const Notifications = (props) => {
  return ( 
    <>
    {props.isOpen? (
      <NotificationType isOpen={props.isOpen} handleOpen={props.handleOpen} color={props.color} title={props.title} message={props.message}/>
    ): null}
    </>
  );
}
 
export default Notifications;