import React from "react";
import cloneDeep from "lodash/cloneDeep"

// reactstrap components
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  Card,
  CardBody,
  Row,
  Col,
  Table
} from "reactstrap";

const MarginCalc = ({
  marginBetween,
  setMarginBetween,
  marginBetweenOnChange,
  removeMarginBetween,

  marginsData,
  currency
}) => {

  const applyMargin = (value) => {
    setMarginBetween(value.marginBetween)
  }

  const addCondition = () => {
    if(marginBetween[marginBetween.length -1]){
      // setMarginBetween(marginBetween.concat({
      //   maxAmt: (parseFloat(marginBetween[marginBetween.length -1].maxAmt) + 0.0001).toFixed(4),
      //   percent: 0
      // }))
      setMarginBetween(marginBetween.concat({
        maxAmt: 0,
        percent: 0
      }))
    }else{
      setMarginBetween(marginBetween.concat({
        maxAmt: 0,
        percent: 0
      }).concat({
        maxAmt: 0,
        percent: 0
      }))
    }
  }

  const lastTwoInput = (e, i) => {
    let cloneMarginBetween = cloneDeep(marginBetween)

    cloneMarginBetween[i]['maxAmt'] = Number(e.target.value)

    if(marginBetween.length -2 === i){
      if(e.target.value){
        cloneMarginBetween[i+1]['maxAmt'] = (parseFloat(e.target.value) + 0.0001).toFixed(4) 
      }else{
        cloneMarginBetween[i+1]['maxAmt'] = (0.0001).toFixed(4)
      }
    }

    
    setMarginBetween(cloneMarginBetween)
  }

  return (  
    <>
     <Card className="shadow">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className="text-uppercase font-weight-bold">
                Profit Margin
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <UncontrolledDropdown nav inNavbar size="sm">
                  <DropdownToggle caret color="secondary" className="text-uppercase">
                    Apply Margin
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                      {
                        marginsData.map((x, i) => {
                          return (
                            <DropdownItem
                              key={i} 
                              className="text-capitalize" 
                              value={x.marginName} 
                              onClick={() => {
                                applyMargin(x)
                              }}
                            >
                              {x.marginName}
                            </DropdownItem>
                          )
                        })
                      }
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => addCondition() }
                >
                  <div className="btn-inner--text">
                    <span>Add Condition</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mac-scrollbar-white">
            <Table className="align-items-center table-flush" size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Max Amt.<br/>({currency?currency:'MYR'})</th>
                  <th></th>
                  <th></th>
                  <th> Profit Margin (%)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  marginBetween.length > 0? 
                    marginBetween.map((x, i) => {
                      return (
                        <tr key={i} className="text-uppercase">
                          <th scope="row">
                            {marginBetween.length === i+1? (
                              <>
                              {"Above "} ({currency?currency:'MYR'})
                              </>
                            ): (
                              <>
                                {(i+1)}. ({currency?currency:'MYR'})
                              </>
                            )}
                          </th>
                          <td className="px-1">
                            <Input
                              className="form-control w-auto"
                              placeholder="Amount Charge"
                              type="number"
                              bsSize="sm"
                              min="0"
                              step=".01"
                              value={Number(x.maxAmt).toString()}
                              disabled={marginBetween.length === i+1?true:false}
                              onChange={(e) => lastTwoInput(e, i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </td>
                          {marginBetween.length === i+1? (
                            <td></td>
                          ): (
                              <>
                                <td>{"& below"}</td>
                              </>
                            )}
                          <td>{"add"}</td>
                          <td className="px-1">
                            <Input
                              className="form-control-alternative w-auto d-inline-block"
                              placeholder="Percent Charge(%)"
                              type="number"
                              bsSize="sm"
                              min="0"
                              value={Number(x.percent).toString()}
                              onChange={(e) => marginBetweenOnChange(Number(e.target.value), 'percent', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                            <b className="mx-1">%</b>
                          </td>
                          <td>
                            <i 
                            className="ni ni-fat-remove"
                            style={{fontSize: '1rem', cursor:'pointer'}}
                            onClick={() => removeMarginBetween(i)}
                            />
                          </td>
                        </tr>
                      )
                    }): null
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
 
export default MarginCalc;