import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';

// const socket = io('http://localhost:3030/');   // local
// const socket = io('https://wordpress-api.printboxer.com/');   // live
// const socket = io('https://pricecal-api.expressprint.com.my/');   // live
const socket = io('https://pricecal-api.ipprinting.my/');   // live
const app = feathers();

app.configure(socketio(socket)
// ,{
//   pingTimeOut:6000
// }
);
app.configure(authentication({
  storage: window.localStorage
}));

export default app;

// import axios from "axios";

// const socket = ('http://localhost:5000/');

// const service = (service) => {
//   return {
//     find: async function(params){
//       let result = await axios.get(`${socket}findModel`,{
//         params: {
//           service: service,
//           params: params
//         },
//       })
//       return result.data
//     },
//     get: async function(id){
//       let result = await axios.get(`${socket}getModel`,{
//         params: {
//           service: service,
//           id: id
//         },
//       })
//       return result.data
//     },
//     create: async function(data){
//       let result = await axios.post(`${socket}createModel`,{
//         service: service,
//         data: data
//       })
//       return result.data
//     }
//   }
// }

// const authenticate = () => {

// }

// const logout = () => {

// }

// const io = {
//   io: {
//     uri: socket
//   }
// }

// const client = {
//   authenticate,
//   service,
//   io,
//   logout,
// }

// export default client;

// app.get('/findModel', async (req, res) => {
//   res.send(await app.service(req.query.service).find(req.query.params))
// });

// app.get('/getModel', async (req, res) => {
//   res.send(await app.service(req.query.service).get(req.query.id))
// });

// app.post('/createModel', async (req, res) => {
//   res.send(await app.service(req.body.params.service).create(req.body.params.data))
// });